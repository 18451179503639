import { EditorState } from "lexical";
import { DocumentConfigModel } from "../Models";
import { TextTypeModel } from "../Models/DocumentConfigModel";
import Constants from "../Common/Constants";

const alignFormats = ['center', 'left', 'right', 'justify'];

class RTEditorService {
  listUniqueId = 1;

  getDocConfigs = (editorState: EditorState, id: string) => {
    const jsonEditorState = editorState.toJSON();
    //console.log(`jsonEditorState-${id}`, jsonEditorState);

    const documentConfigs: DocumentConfigModel[] = [];

    jsonEditorState.root.children.forEach((child: any, i: number) => {
      switch (child.type) {
        case "paragraph":
          const rowId = `para-${id}-${i}`;
          documentConfigs.push(
            ...this.getParagraphConfigs(child.children, rowId, child.format)
          );
          break;
        case "heading":
          documentConfigs.push(
            this.getHeadingConfigs(child.children, child.tag)
          );
          break;
        case "horizontalrule":
          documentConfigs.push(this.getHorizontalRule());
          break;
        case "table":
          documentConfigs.push(this.getTableConfig(child.children));
          break;
        case "list":
          documentConfigs.push(
            ...this.getListConfigs(child.tag, child.listType, child.children)
          );
          break;
        default:
          break;
      }
    });

    if (id === 'basicPrinciple') {
      documentConfigs.push(this.getHorizontalRule());
      documentConfigs.push({
        type: 'TEXT',
        value: '',
        textTypes: [],
        sectionType: 'CHILDREN',
      });
    }

    return documentConfigs;
  };

  private getParagraphConfigs = (childrens: any[], rowId?: string, format?: string) => {
    const paraConfigs: DocumentConfigModel[] = [];
    let isRow = false;

    if (rowId) {
      isRow = true;
    }

    childrens.forEach((c: any) => {
      const { text, type, src, height, width, maxWidth } = c;
      const paraConfig: DocumentConfigModel = {
        type: type.toUpperCase(),
        sectionType: "CHILDREN",
        textTypes: [],
        value: text,
        elementId: isRow ? rowId : undefined,
        elementType: isRow ? "ROW" : undefined,
        uid: rowId,
      };

      if (format && alignFormats.includes(format)) {
        paraConfig.textTypes.push({ key: 'text-align', value: format });
      }

      switch (type) {
        case "link": 
          paraConfig.type = "TEXT";
          paraConfig.value = c.children ? c.children[0].text : '';

          if (c.children) {
            const textTypes = this.getTextTypes(c.children[0]);
            paraConfig.textTypes.push(...textTypes);
          }

          break;
        case "linebreak":
          paraConfig.value = '';
        break;
        case "list":
          paraConfigs.push(
            ...this.getListConfigs(c.tag, c.listType, c.children)
          );
          break;
        case "image":
          paraConfig.value = src;

          if (width || maxWidth) {
            paraConfig.textTypes.push({ key: 'width', value: width || maxWidth });
          }

          if (height) {
            paraConfig.textTypes.push({ key: 'height', value: height });
          }
          break;
        default:
          break;
      }

      const textTypes = this.getTextTypes(c);

      if (paraConfig.textTypes.length > 0) {
        textTypes.forEach((textType) => {
          const paraTextType = paraConfig.textTypes.find(p => p.key === textType.key);

          if (!paraTextType) {
            paraConfig.textTypes.push(textType);
          }
        });
      } else {
        paraConfig.textTypes.push(...textTypes);
      }

      paraConfigs.push(paraConfig);
    });

    if (paraConfigs.length < 1) {
      const spaceConfig: DocumentConfigModel = {
        type: "TEXT",
        sectionType: "CHILDREN",
        textTypes: [],
        value: "",
      };
      paraConfigs.push(spaceConfig);
    }

    console.log("paraConfigs -> ", paraConfigs)

    return paraConfigs;
  };

  private getHorizontalRule = () => {
    const horizontalRuleConfig: DocumentConfigModel = {
      type: "TEXT",
      sectionType: "CHILDREN",
      textTypes: [],
      value: ""
    };

    horizontalRuleConfig.textTypes.push({ key: "HORIZONTAL_LINE" });

    return horizontalRuleConfig;
  };

  private getTableConfig = (rows: any[]) => {
    const tableConfig: DocumentConfigModel = {
      type: "TABLE",
      sectionType: "CHILDREN",
      textTypes: [],
      value: "",
      documentConfigs: [],
    };

    rows.forEach((row: any) => {
      const rowConfig: DocumentConfigModel = {
        type: "TABLE_ROW",
        sectionType: "CHILDREN",
        textTypes: [],
        value: "",
        documentConfigs: [],
      };

      row.children.forEach((cell: any, rowNum: number) => {
        const { colSpan, rowSpan, backgroundColor, width } = cell;

        const cellTextTypes: TextTypeModel[] = [];

        if (colSpan) {
          cellTextTypes.push({ key: 'COL_SPAN', value: colSpan });
        }

        if (rowSpan) {
          cellTextTypes.push({ key: 'ROW_SPAN', value: rowSpan });
        }

        if (backgroundColor) {
          cellTextTypes.push({ key: 'BG_COLOR', value: backgroundColor });
        }

        if (width) {
          cellTextTypes.push({ key: 'WIDTH', value: width * 20 });
        }

        const cellConfig: DocumentConfigModel = {
          type: "TABLE_CELL",
          sectionType: "CHILDREN",
          textTypes: cellTextTypes,
          value: "",
          documentConfigs: [],
        };

        cell.children.forEach((child: any, i: number) => {
          switch (child.type) {
            case "paragraph":
              const rowId = `para-${rowNum}-${i}`;
              cellConfig.documentConfigs?.push(
                ...this.getParagraphConfigs(child.children, rowId, child.format)
              );
              break;
            case "heading":
              cellConfig.documentConfigs?.push(
                this.getHeadingConfigs(child.children, child.tag)
              );
              break;
            case "horizontalrule":
              cellConfig.documentConfigs?.push(this.getHorizontalRule());
              break;
            case "table":
              cellConfig.documentConfigs?.push(this.getTableConfig(child.children));
              break;
            case "list":
              cellConfig.documentConfigs?.push(
                ...this.getListConfigs(child.tag, child.listType, child.children)
              );
              break;
            default:
              break;
          }
        });

        rowConfig.documentConfigs?.push(cellConfig);
      });

      tableConfig.documentConfigs?.push(rowConfig);
    });

    return tableConfig;
  };

  private getHeadingConfigs = (childrens: any[], tag?: string) => {
    const headerConfig: DocumentConfigModel = {
      type: "TEXT",
      sectionType: "CHILDREN",
      textTypes: [],
      value: "",
    };
    childrens.forEach((c: any) => {
      const { text } = c;
      // console.log(text, "text");
      // console.log(tag, "tag");

      let textKey = "";
      switch (tag) {
        case "h1":
          textKey = "header-one";
          break;
        case "h2":
          textKey = "header-two";
          break;
        case "h3":
          textKey = "header-three";
          break;
      }

      headerConfig.textTypes.push({ key: textKey });
      headerConfig.value = text;
    });
    return headerConfig;
  };

  private buildListConfigs = (c: any, i: number, type: string, typeId: string) => {
    const listConfigs: DocumentConfigModel[] = [];

    const listChildren = c.children && c.children.find((p: any) => p.type === 'list');

    if (listChildren) {
      listChildren.children.forEach((child: any, lid: number) => {
        const listChildChildren = child.children.find((p: any) => p.type === 'list');

        if (listChildChildren) {
          listChildChildren.children.forEach((child: any, lcid: number) => {
            listConfigs.push(...this.buildListConfigs(child, ((i + lid + lcid + 1) * 5), type, typeId));
          });
        } else {
          
          const rowId = `nl-${this.listUniqueId}-${i}-${lid}`;
          const configs = this.getParagraphConfigs(child.children, rowId, child.format);

          configs.forEach((config) => {

            const textType = config.textTypes.find(p => p.key === type);

            if (!textType) {
              config.textTypes.push({
                key: type,
                value: { depth: child.indent },
              });
            }

            if(config.type == "LINEBREAK"){
              config.type = "TEXT";
              config.textTypes.push({
                key: 'break',
                value: 1
              });
            }

          });

          listConfigs.push(...configs);
        }
      });

      console.log("listChildren.children -> ", JSON.stringify(listChildren.children));
    } else {
      
      const rowId = `nl-${this.listUniqueId}-${i}`;
      const configs = this.getParagraphConfigs(c.children, rowId, c.format);

      configs.forEach((config) => {

        const textType = config.textTypes.find(p => p.key === type);

        if (!textType) {
          config.textTypes.push({
            key: type,
            value: { depth: c.indent ?? 0 },
          });
        }

        if(config.type == "LINEBREAK"){
          config.type = "TEXT";
          config.textTypes.push({
            key: 'break',
            value: 1
          });
        }

      });

      listConfigs.push(...configs);
    }

    console.log("listConfigs -> ", listConfigs);

    return listConfigs;
  }

  private getListConfigs = (
    tag: string,
    listType: string,
    childrens: any[]
  ) => {
    const listConfigs: DocumentConfigModel[] = [];

    switch (tag) {
      case "ol":
        switch (listType) {
          case "number":
            childrens.forEach((c, i) => {
              listConfigs.push(...this.buildListConfigs(c, i, 'number-list', 'nl'));
              this.listUniqueId = this.listUniqueId + 1;
            });

            break;
          default:
            break;
        }

        break;
      case "ul":
        switch (listType) {
          case "bullet":
            childrens.forEach((c, i) => {
              listConfigs.push(...this.buildListConfigs(c, i, 'unordered-list-item', 'ul'));
              this.listUniqueId = this.listUniqueId + 1;
            });

            break;
          default:
            break;
        }

        break;
      default:
        break;
    }

    return listConfigs;
  };

  private getTextTypes(children: any) {
    const { format, type, url, style } = children;

    const textTypes: TextTypeModel[] = [];

    switch (type) {
      case "link": //link
        textTypes.push({ key: "LINK", value: { url: url } });
        textTypes.push({ key: 'COLOR', value: undefined });
        break;
      default:
        break;
    }
    if (style) {
      const styles: string[] = style.split(";");
      styles.forEach(style => {
        if (style) {
          const styleItem = style.split(":");
          const styleKey = styleItem[0];
          const styleValue = styleItem[1].trim();

          switch (styleKey) {
            case "background-color":
              textTypes.push({ key: "BG_COLOR", value: styleValue });
              break;
            case "color":
              textTypes.push({ key: "COLOR", value: styleValue });
              break;
            case "font-family":
              textTypes.push({ key: "FONT_FAMILY", value: styleValue });
              break;
            case "font-size":
              let value: any = styleValue.replace('px', '');
              value = Number.parseFloat(value);
              textTypes.push({ key: "FONT_SIZE", value });
              break;
          }
        }
      });
    } else {
      textTypes.push({ key: "FONT_FAMILY", value: Constants.DocFontFamilyRegular });
    }

    switch (format) {
      case 1: // bold
        textTypes.push({ key: "BOLD" });
        break;
      case 2: // italic
        textTypes.push({ key: "ITALIC" });
        break;
      case 3: // bold & italic
        textTypes.push({ key: "BOLD" }, { key: "ITALIC" });
        break;
      case 4: // strikethrough
        textTypes.push({ key: "STRIKETHROUGH" });
        break;
      case 5: // bold, strikethrough
        textTypes.push({ key: "BOLD" }, { key: "STRIKETHROUGH" });
        break;
      case 6: // italic, strikethrough
        textTypes.push({ key: "ITALIC" }, { key: "STRIKETHROUGH" });
        break;
      case 7: // bold, italic, strikethrough
        textTypes.push(
          { key: "BOLD" },
          { key: "ITALIC" },
          { key: "STRIKETHROUGH" }
        );
        break;
      case 8: // underline
        textTypes.push({ key: "UNDERLINE" });
        break;
      case 9: // bold, underline
        textTypes.push({ key: "BOLD" }, { key: "UNDERLINE" });
        break;
      case 10: // italic, underline
        textTypes.push({ key: "ITALIC" }, { key: "UNDERLINE" });
        break;
      case 11: // bold, italic, underline
        textTypes.push(
          { key: "BOLD" },
          { key: "ITALIC" },
          { key: "UNDERLINE" }
        );
        break;
      case 12: // underline, strikethrough
        textTypes.push({ key: "UNDERLINE" }, { key: "STRIKETHROUGH" });
        break;
      case 13: // bold, strikethrough, underline
        textTypes.push(
          { key: "BOLD" },
          { key: "UNDERLINE" },
          { key: "STRIKETHROUGH" }
        );
        break;
      case 14: // underline, italic, strikethrough
        textTypes.push(
          { key: "ITALIC" },
          { key: "UNDERLINE" },
          { key: "STRIKETHROUGH" }
        );
        break;
      case 15: // bold, italic, underline, strikethrough
        textTypes.push(
          { key: "BOLD" },
          { key: "ITALIC" },
          { key: "UNDERLINE" },
          { key: "STRIKETHROUGH" }
        );
        break;
      case 32: // subscript
        textTypes.push({ key: "SUBSCRIPT" });
        break;
      case 33: // subscript, bold
        textTypes.push({ key: "SUBSCRIPT" }, { key: "BOLD" });
        break;
      case 64: // superscript
        textTypes.push({ key: "SUPERSCRIPT" });
        break;
      case 65: // superscript , bold
        textTypes.push({ key: "SUPERSCRIPT" }, { key: "BOLD" });
        break;
      case "center":
        textTypes.push({ key: "text-align", value: "center" });
        break;
      case "right":
        textTypes.push({ key: "text-align", value: "right" });
        break;
      case "left":
        textTypes.push({ key: "text-align", value: "left" });
        break;
      case "justify":
        textTypes.push({ key: "text-align", value: "justify" });
        break;
      default:
        break;
    }

    return textTypes;
  }
}

const rtEditorService = new RTEditorService();
export default rtEditorService;
