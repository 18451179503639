import { Component, Context } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RenewalForm } from '../../Models/RenewalForm'
import ExceptionRenewalFormComponent from './ExceptionRenewalFormComponent'
import { Box, CircularProgress, Divider, FormLabel, Grid, Typography } from '@mui/material'
import BackLinkButton from '../../Components/Button/BackLinkButton'
import PageHeader from '../../Components/Text/PageHeader'
import WhiteButton from '../../Components/Button/WhiteButton'
import RedButton from '../../Components/Button/RedButton'
import moment from 'moment'
import exceptionRenewService from '../../Services/ExceptionRenewal'
import MyCustomSnackbar from '../../Components/SnackBar/MyCustomSnackbar'
import SelectModel from '../../Components/Select/SelectModel'
import lptService from '../../Services/LPTService'
import ExceptionFormComponent from './ExceptionFormComponent'
import CollapsibleCard from '../../Components/Card/CollapsibleCard'
import gptService from '../../Services/GPTService'
import IconButton from '../../Components/Button/IconButton'
import { RoleContext } from '../../Contexts'
import UnauthorizedRoute from '../../Components/Route/UnauthorizedRoute'
import { AuthorizationService } from '../../Services'

type ScreenMode = "view" | "edit"

interface Props extends RouteComponentProps<any, any, any> { }

interface State {
    renewalForm: RenewalForm;
    mode: ScreenMode;
    throwErr: boolean;
    throwSuccess: boolean;
    errMsg: string;
    successMsg: string;
    lperId?: number;
    procedureNames: any[];
    geographyScope: any[];
    businessScope: any[];
    exceptiongeography: any[];
    exceptionBusinessArea: any[];
    proceduresData: any[];
    load: boolean;
    approverNames: any[];
    m4LegalAttrLoading: boolean;
    gmRegionalLoading: boolean;
    ibuHubGmLoading: boolean;
    assocVpECOLoading: boolean;
    m4EcoBlLoading: boolean;
    m4LegalAttrFRAPLoading: boolean;
    gmFuncVPLoading: boolean;
    assocVpIBULoading: boolean;
    chiefAccOffLoading: boolean;
    chiefProcOfcLoading: boolean;
    localBusOwnerLoading: boolean;
    localChiefFinOfcLoading: boolean;
    SenrEntrLoading: boolean;
    SeniorVPCFOLoading: boolean;
    localCFOLoading: boolean;
    m5F12BLLoading: boolean;
    m4LegalAttorneySpEcoBlVal: any;
    gmRegionalAffiliateVpVal: any;
    ibuHubGmVal: any;
    associateVpEcoIbuVal: any;
    associateVpLegalIbuVal: any;
    m4EcoBlVal: any;
    m4LegalAttrEcoBlFrapVal: any;
    gmFunctionVpr8Val: any;
    chiefAccOfficerVal: any;
    chiefProcOfficerVal: any;
    localBusinessOwnerVal: any;
    localChiefFinanOwnerVal: any;
    seniorDirEnterTravExpenVal: any;
    seniorVpChiefFinanOffcVal: any;
    localCfoVal: any;
    m5F12BusinessLeaderVal: any;
}

class ExceptionRenewalViewPage extends Component<Props, State> {
    static contextType?: Context<any> = RoleContext;
    context!: React.ContextType<typeof RoleContext>;
    constructor(props: Props) {
        super(props)
        this.state = {
            renewalForm: {
                exceptionSummary: "",
                expirationDate: null,
                // legalComments: "",
                // legalConsultDate: null,
                legalRenewalConsultDate: null,
                // policies: "",
                // procedureOwner: "",
                // procedureOwnerComments: "",
                // procedureOwnerConsultDate: null,
                procedureOwnerRenewalDate: null,
                rationaleForRenewal: "",
                renewal: 0,
                renewalRequestedBy: "",
                renewalSubmittedBy: "",
                exceptionStatus: "",
                title: "",
                businessAreaScope: "",
                ecGeographicm4: "",
                exceptionClassification: "",
                exceptionCompletedBy: "",
                exceptionDescription: "",
                exceptionExpirationDate: null,
                exceptionImpactAfterGrant: "",
                exceptionRationale: "",
                exceptionRequestBy: "",
                exceptionTypeForApproval: "",
                geographyScopeException: "",
                procedure: "",
                requestedTextFromProcedure: "",
                ecAssociateVpOpConsultDate: null,
                ecoBlConsultDate: null,
                ecGeographicm4ConsultDate: null,
                exceptionEffectiveDate: null,
                legalConsultDate: null,
                procedureOwnerConsultDate: null,
            },
            mode: "view",
            errMsg: "",
            successMsg: "",
            throwErr: false,
            throwSuccess: false,
            exceptionBusinessArea: [],
            exceptiongeography: [],
            proceduresData: [],
            procedureNames: [],
            load: true,
            businessScope: [],
            geographyScope: [],
            approverNames: [],
            m4LegalAttrLoading: false,
            assocVpECOLoading:false,
            assocVpIBULoading:false,
            chiefAccOffLoading:false,
            chiefProcOfcLoading:false,
            gmFuncVPLoading:false,
            gmRegionalLoading:false,
            ibuHubGmLoading:false,
            localBusOwnerLoading:false,
            localCFOLoading:false,
            localChiefFinOfcLoading:false,
            m4EcoBlLoading:false,
            m4LegalAttrFRAPLoading:false,
            m5F12BLLoading:false,
            SeniorVPCFOLoading:false,
            SenrEntrLoading:false,
            associateVpEcoIbuVal: {},
            associateVpLegalIbuVal: {},
            chiefAccOfficerVal: {},
            chiefProcOfficerVal: {},
            gmFunctionVpr8Val: {},
            gmRegionalAffiliateVpVal: {},
            ibuHubGmVal: {},
            localBusinessOwnerVal: {},
            localCfoVal: {},
            localChiefFinanOwnerVal: {},
            m4EcoBlVal: {},
            m4LegalAttorneySpEcoBlVal: {},
            m4LegalAttrEcoBlFrapVal: {},
            m5F12BusinessLeaderVal: {},
            seniorDirEnterTravExpenVal: {},
            seniorVpChiefFinanOffcVal: {}
        }
    }


    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): Promise<void> {
        if (prevState.lperId !== this.state.lperId) {
            await this.getRenewalData();
        }
    }

    getRenewalData = async () => {
        
        const { lperId } = this.state;
        console.log('lperId',lperId)
        try {
            const data = await exceptionRenewService.getRenewalDataById(lperId!);
            const procedure = data?.procedure.split(",").map((str: any) => { return { text: str, value: str } })
            const geography = data?.geographyScopeException.split(",").map((str: any) => { return { text: str, value: str } })
            const businessScope = data?.businessAreaScope.split(",").map((str: any) => { return { text: str, value: str } })
            this.setState({
                renewalForm: data!, procedureNames: procedure!, geographyScope: geography!, businessScope: businessScope!, load: false
            })
            if (data?.associateVpEcoIbuName !== null) {
                this.setState({
                    associateVpEcoIbuVal: {
                        text: data?.associateVpEcoIbuName,
                        value: data?.associateVpEcoIbuName,
                        email: data?.associateVpEcoIbuEmail,
                    },
                });
            } else {
                this.setState({
                    associateVpEcoIbuVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.associateVpLegalIbuName !== null) {
                this.setState({
                    associateVpLegalIbuVal: {
                        text: data?.associateVpLegalIbuName,
                        value: data?.associateVpLegalIbuName,
                        email: data?.associateVpLegalIbuEmail,
                    },
                });
            } else {
                this.setState({
                    associateVpLegalIbuVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.m4LegalAttrEcoBlFrapName !== null) {
                this.setState({
                    m4LegalAttrEcoBlFrapVal: {
                        text: data?.m4LegalAttrEcoBlFrapName,
                        value: data?.m4LegalAttrEcoBlFrapName,
                        email: data?.m4LegalAttrEcoBlFrapEmail,
                    },
                });
            } else {
                this.setState({
                    m4LegalAttrEcoBlFrapVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.chiefAccOfficerName !== null) {
                this.setState({
                    chiefAccOfficerVal: {
                        text: data?.chiefAccOfficerName,
                        value: data?.chiefAccOfficerName,
                        email: data?.chiefAccOfficerEmail,
                    },
                });
            } else {
                this.setState({
                    chiefAccOfficerVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.chiefProcOfficerName !== null) {
                this.setState({
                    chiefProcOfficerVal: {
                        text: data?.chiefProcOfficerName,
                        value: data?.chiefProcOfficerName,
                        email: data?.chiefProcOfficerEmail,
                    },
                });
            } else {
                this.setState({
                    chiefProcOfficerVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.gmFunctionVpr8Name !== null) {
                this.setState({
                    gmFunctionVpr8Val: {
                        text: data?.gmFunctionVpr8Name,
                        value: data?.gmFunctionVpr8Name,
                        email: data?.gmFunctionVpr8Email,
                    },
                });
            } else {
                this.setState({
                    gmFunctionVpr8Val: { text: "", value: "", email: "" },
                });
            }
            if (data?.gmRegionalAffiliateVpName !== null) {
                this.setState({
                    gmRegionalAffiliateVpVal: {
                        text: data?.gmRegionalAffiliateVpName,
                        value: data?.gmRegionalAffiliateVpName,
                        email: data?.gmRegionalAffiliateVpEmail,
                    },
                });
            } else {
                this.setState({
                    gmRegionalAffiliateVpVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.ibuHubGmName !== null) {
                this.setState({
                    ibuHubGmVal: {
                        text: data?.ibuHubGmName,
                        value: data?.ibuHubGmName,
                        email: data?.ibuHubGmEmail,
                    },
                });
            } else {
                this.setState({
                    ibuHubGmVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.localBusinessOwnerName !== null) {
                this.setState({
                    localBusinessOwnerVal: {
                        text: data?.localBusinessOwnerName,
                        value: data?.localBusinessOwnerName,
                        email: data?.localBusinessOwnerEmail,
                    },
                });
            } else {
                this.setState({
                    localBusinessOwnerVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.localCfoName !== null) {
                this.setState({
                    localCfoVal: {
                        text: data?.localCfoName,
                        value: data?.localCfoName,
                        email: data?.localCfoEmail,
                    },
                });
            } else {
                this.setState({
                    localCfoVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.localChiefFinanOwnerName !== null) {
                this.setState({
                    localChiefFinanOwnerVal: {
                        text: data?.localChiefFinanOwnerName,
                        value: data?.localChiefFinanOwnerName,
                        email: data?.localChiefFinanOwnerEmail,
                    },
                });
            } else {
                this.setState({
                    localChiefFinanOwnerVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.m4EcoBlName !== null) {
                this.setState({
                    m4EcoBlVal: {
                        text: data?.m4EcoBlName,
                        value: data?.m4EcoBlName,
                        email: data?.m4EcoBlEmail,
                    },
                });
            } else {
                this.setState({
                    m4EcoBlVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.m4LegalAttorneySpEcoBlName !== null) {
                this.setState({
                    m4LegalAttorneySpEcoBlVal: {
                        text: data?.m4LegalAttorneySpEcoBlName,
                        value: data?.m4LegalAttorneySpEcoBlName,
                        email: data?.m4LegalAttorneySpEcoBlEmail,
                    },
                });
            } else {
                this.setState({
                    m4LegalAttorneySpEcoBlVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.m5F12BusinessLeaderName !== null) {
                this.setState({
                    m5F12BusinessLeaderVal: {
                        text: data?.m5F12BusinessLeaderName,
                        value: data?.m5F12BusinessLeaderName,
                        email: data?.m5F12BusinessLeaderEmail,
                    },
                });
            } else {
                this.setState({
                    m5F12BusinessLeaderVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.seniorDirEnterTravExpenName !== null) {
                this.setState({
                    seniorDirEnterTravExpenVal: {
                        text: data?.seniorDirEnterTravExpenName,
                        value: data?.seniorDirEnterTravExpenName,
                        email: data?.seniorDirEnterTravExpenEmail,
                    },
                });
            } else {
                this.setState({
                    seniorDirEnterTravExpenVal: { text: "", value: "", email: "" },
                });
            }
            if (data?.seniorVpChiefFinanOffcName !== null) {
                this.setState({
                    seniorVpChiefFinanOffcVal: {
                        text: data?.seniorVpChiefFinanOffcName,
                        value: data?.seniorVpChiefFinanOffcName,
                        email: data?.seniorVpChiefFinanOffcEmail,
                    },
                });
            } else {
                this.setState({
                    seniorVpChiefFinanOffcVal: { text: "", value: "", email: "" },
                });
            };
            console.log("Renewal Data", data);

        } catch (error) {
            this.setState({ throwErr: true, errMsg: "Something went wrong while fetching data." })
            console.error("err", error)
        }
    }

    

    handleInputChange = (event: any) => {
        this.setState({
            renewalForm: {
                ...this.state.renewalForm, [event.target.name]: event.target.value
            }
        })
    }
    onDropDownChange = (selected: SelectModel) => {
        const { renewalForm } = this.state;
        const value = selected.value;
        this.setState({
            renewalForm: { ...renewalForm, status: value },
        });
    };

    handleExpirationDateChange = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, expirationDate: value! },
        });
    };
    handleLegalConsultDateChange = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, legalRenewalConsultDate: value! },
        });
    };
    handleProcedureOwnerDateChange = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, procedureOwnerRenewalDate: value! },
        });
    };

    action = (
        <>
         
          <IconButton
            IconType="Cancel"
            onClick={() => {
              this.setState({ throwSuccess:false });
            }}
          />
        </>
      );

    render() {
        const { renewalForm, mode, load, errMsg, successMsg, throwErr, throwSuccess } = this.state;
        const { state } = this.props.location;
        console.log('this.props.location', this.props.location);
        if (load) {
            return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
        }
        const isReadonlyUser = AuthorizationService.isReadonlyUser(this.context);
        if (isReadonlyUser) {
            if (!(mode && mode.toLocaleLowerCase() === 'view')) {
              return <UnauthorizedRoute />;
            }
          }
        
        return (
            <Box>
                <MyCustomSnackbar
                    message={errMsg}
                    severity="warning"
                    actions={<></>}
                    open={throwErr}
                    onClose={() => {
                        this.setState({ throwErr: false });
                    }}
                />
                <MyCustomSnackbar
                    message={successMsg}
                    severity="success"
                    actions={this.action}
                    open={throwSuccess}
                    onClose={() => {
                        this.setState({ throwSuccess: false });
                    }}
                />
                {state && state.success ?

                    <MyCustomSnackbar
                        message={state.msg}
                        severity="success"
                        actions={this.action}
                        open={state.success}
                        onClose={() => {
                            this.setState({ throwSuccess: false });
                        }}
                    /> : <></>}
                <Grid container
                    direction={"row"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1em",
                        justifyContent: "space-between",
                    }}>
                    <Grid item>
                        <Grid container spacing={2} style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Grid item>
                                <BackLinkButton
                                    onClick={() => {
                                        // let state: any = this.props.location.state;
                                        // this.props.history.push(`/eco_localization/${this.props.history?.location?.state?.editTemplate?.lpId}/mode=edit`, { ...state, "from": "/exception" });
                                        this.props.history.push('/procedure_exception')
                                    }}
                                />
                            </Grid>
                            <Grid style={{ display: "flex", justifyContent: "flex-start" }} item sx={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                maxWidth: '42em'
                            }}>
                                <PageHeader label={`Procedure Exception Renewal Form`} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.mode === 'edit' && !isReadonlyUser ? <Grid item style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <WhiteButton label="Cancel" onClick={() => {
                                    this.setState({ mode: 'view' })
                                    this.props.history.push(`/exception_renew/${this.state.renewalForm?.lperId}?mode=view`)
                                }} />
                            </Grid>
                            <Grid item>
                                <WhiteButton label="Save as Draft" onClick={this.handleSaveAsDraft} />
                            </Grid>
                            <Grid item>
                                <RedButton label="Submit for Approval" onClick={this.handleSubmitForApproval} />
                            </Grid>
                        </Grid>
                    </Grid> :
                        <Grid item style={{ display: "flex", justifyContent: 'flex-end' }}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <WhiteButton label="Cancel" onClick={() => {
                                        this.props.history.push(`/procedure_exception`);

                                    }} />
                                </Grid>
                               {!isReadonlyUser ? <Grid item>
                                    <WhiteButton label="Edit"
                                        onClick={() => {
                                            this.setState({ mode: "edit" });
                                            this.props.history.push(
                                                `/exception_renew/${this.state.renewalForm.lperId}?mode=edit`
                                            );
                                        }}
                                    />
                                </Grid>: null}
                            </Grid>
                        </Grid>}
                </Grid>
                <Divider />
                {mode === "edit" ?
                    <>
                        <ExceptionRenewalFormComponent
                            renewalForm={renewalForm}
                            onTextInputChange={this.handleInputChange}
                            handleExpirationDateChange={this.handleExpirationDateChange}
                            handleLegalConsultDateChange={this.handleLegalConsultDateChange}
                            handleProcedureOwnerDateChange={this.handleProcedureOwnerDateChange}
                            onDropDownChange={this.onDropDownChange}
                        />
                        <ExceptionFormComponent
                            exceptionForm={renewalForm}
                           
                            businessScope={this.state.businessScope}
                            geographyScope={this.state.geographyScope}
                            handleEcAssociateVPDateChange={this.handleEcAssociateVPDateChange}
                            handleEcAssociateVpOpRadioButtons={this.handleEcAssociateVpOpRadioButtons}
                            handleEcBlConsultDateChange={this.handleEcBlConsultDateChange}
                            handleEcGeographicDateChange={this.handleEcGeographicDateChange}
                            handleExceptionEffDateChange={this.handleExceptionEffDateChange}
                            handleExceptionExpiryDateChange={this.handleExceptionExpiryDateChange}
                            handleLegalConsultDateChange={this.handleLegalConsultDateChangeException}
                            handleProcedureOwnerDateChange={this.handleProcedureOwnerDateChangeException}
                            handleexceptionClassificationRadioButtons={this.handleexceptionClassificationRadioButtons}
                            onDropDownChange={this.onDropDownChangeException}
                            onSelectBusinessScope={this.onSelectBusinessScope}
                            onSelectGeography={this.onSelectGeographyScope}
                            onSelectProcedure={this.onSelectProcedure}
                            load={this.state.load}
                            onTextInputChange={this.handleTextInputChange}
                            procedureNames={this.state.procedureNames}
                            exceptionBusinesData={this.state.exceptionBusinessArea}
                            geographyScopeData={this.state.exceptiongeography}
                            procedureNameData={this.state.proceduresData}
                            ibuHubGmLoading={this.state.ibuHubGmLoading}
                        localBusOwnerLoading={this.state.localBusOwnerLoading}
                        localCFOLoading={this.state.localCFOLoading}
                        localChiefFinOfcLoading={this.state.localChiefFinOfcLoading}
                        m4EcoBlLoading={this.state.m4EcoBlLoading}
                        m4LegalAttrFRAPLoading={this.state.m4LegalAttrFRAPLoading}
                        m4LegalAttrLoading={this.state.m4LegalAttrLoading}
                        SeniorVPCFOLoading={this.state.SeniorVPCFOLoading}
                        SenrEntrLoading={this.state.SenrEntrLoading}
                        assocVpECOLoading={this.state.assocVpECOLoading}
                        assocVpIBULoading={this.state.assocVpIBULoading}
                        chiefAccOffLoading={this.state.chiefAccOffLoading}
                        chiefProcOfcLoading={this.state.chiefProcOfcLoading}
                        gmRegionalLoading={this.state.gmRegionalLoading}
                        gmFuncVPLoading={this.state.gmFuncVPLoading}
                        m5F12BLLoading={this.state.m5F12BLLoading}
                            associateVpEcoIbuVal={this.state.associateVpEcoIbuVal}
                            associateVpLegalIbuVal={this.state.associateVpLegalIbuVal}
                            chiefAccOfficerVal={this.state.chiefAccOfficerVal}
                            chiefProcOfficerVal={this.state.chiefProcOfficerVal}
                            gmFunctionVpr8Val={this.state.gmFunctionVpr8Val}
                            gmRegionalAffiliateVpVal={this.state.gmRegionalAffiliateVpVal}
                            ibuHubGmVal={this.state.ibuHubGmVal}
                            approverNames={this.state.approverNames}
                            localBusinessOwnerVal={this.state.localBusinessOwnerVal}
                            localCfoVal={this.state.localCfoVal}
                            localChiefFinanOwnerVal={this.state.localChiefFinanOwnerVal}
                            m4EcoBlVal={this.state.m4EcoBlVal}
                            m4LegalAttorneySpEcoBlVal={this.state.m4LegalAttorneySpEcoBlVal}
                            m4LegalAttrEcoBlFrapVal={this.state.m4LegalAttrEcoBlFrapVal}
                            m5F12BusinessLeaderVal={this.state.m5F12BusinessLeaderVal}
                            seniorDirEnterTravExpenVal={this.state.seniorDirEnterTravExpenVal}
                            seniorVpChiefFinanOffcVal={this.state.seniorVpChiefFinanOffcVal}
                            setAssociateVpEcoIbuValue={this.setAssociateVpEcoIbuInputValue}
                            setAssociateVpLegalIbuValue={this.setAssociateVpLegalIbuInputValue}
                            setChiefAccOfficerValue={this.setChiefAccOfficerInputValue}
                            setChiefProcOfficerValue={this.setChiefProcOfficerInputValue}
                            setGmFunctionVpr8Value={this.setGmFunctionVpr8InputValue}
                            setGmRegionalAffiliateVpValue={this.setGmRegionalAffiliateVpInputValue}
                            setIbuHubGmValue={this.setIbuHubGmInputValue}
                            setLocalBusinessOwnerValue={this.setLocalBusinessOwnerValue}
                            setLocalCfoValue={this.setLocalCfoValue}
                            setLocalChiefFinanOwnerValue={this.setLocalChiefFinanOwnerValue}
                            setM4EcoBlValue={this.setM4EcoBlValue}
                          setSeniorDirEnterTravExpenValue={this.setSeniorDirEnterTravExpenValue}
                          setM4LegalAttorneySpEcoBlValue={this.setM4LegalAttorneySpEcoBlValue}
                            setM4LegalAttrEcoBlFrapValue={this.setM4LegalAttrEcoBlFrapValue}
                            setM5F12BusinessLeaderValue={this.setM5F12BusinessLeaderValue}
                            setSeniorVpChiefFinanOffcValue={this.setSeniorVpChiefFinanOffcValue}
                            onAssociateVpEcoIbuSelect={this.onAssociateVpEcoIbuSelect}
                            onAssociateVpLegalIbuSelect={this.onAssociateVpLegalIbuSelect}
                            onChiefAccOfficerSelect={this.onChiefAccOfficerSelect}
                            onChiefProcOfficerSelect={this.onChiefProcOfficerSelect}
                            onGmFunctionVpr8Select={this.onGmFunctionVpr8Select}
                            onGmRegionalAffiliateVpSelect={this.onGmRegionalAffiliateVpSelect}
                            onIbuHubGmSelect={this.onIbuHubGmSelect}
                            onLocalBusinessOwnerSelect={this.onLocalBusinessOwnerSelect}
                            onLocalCfoSelect={this.onLocalCfoSelect}
                            onLocalChiefFinanOwnerSelect={this.onLocalChiefFinanOwnerSelect}
                            onM4EcoBlSelect={this.onM4EcoBlSelect}
                            onM4LegalAttorneySpEcoBlSelect={this.onM4LegalAttorneySpEcoBlSelect}
                            onM4LegalAttrEcoBlFrapSelect={this.onM4LegalAttrEcoBlFrapSelect}
                            onM5F12BusinessLeaderSelect={this.onM5F12BusinessLeaderSelect}
                            onSeniorDirEnterTravExpenSelect={this.onSeniorDirEnterTravExpenSelect}
                            onSeniorVpChiefFinanOffcSelect={this.onSeniorVpChiefFinanOffcSelect}


                        />
                    </>
                    :
                    <>
                        <CollapsibleCard
                            title='Renewal Form'
                            divider
                            initiallyOpen
                            titleStyle={{ fontSize: '26px' }}

                        >

                            <Grid container spacing={2} columns={6}>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>
                                        Title
                                    </Typography>
                                    <Typography>{renewalForm.title}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>
                                        Exception Summary
                                    </Typography>
                                    <Typography>{renewalForm.exceptionSummary ? renewalForm.exceptionSummary : `__`}</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} columns={6}>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>
                                        Renewal #
                                    </Typography>
                                    <Typography>{renewalForm.renewal}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>
                                       Renewal Status
                                    </Typography>
                                    <Typography>{renewalForm.status}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>
                                        Renewal Requested By
                                    </Typography>
                                    <Typography>{renewalForm.renewalRequestedBy}</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} columns={6}>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>
                                        Renewal Submitted By
                                    </Typography>
                                    <Typography>{renewalForm.renewalSubmittedBy}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>
                                        Expiration Date
                                    </Typography>
                                    <Typography>{renewalForm.expirationDate ? moment(renewalForm.expirationDate).format("L") : `_`}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>Rationale for Renewal</Typography>
                                    <Typography>{renewalForm.rationaleForRenewal}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} columns={8}>
                                <Grid item xs={2.67}>
                                    <Typography style={{fontWeight:'bolder'}}>Legal Renewal Consult</Typography>
                                    <Typography>{renewalForm.legalRenewalConsultDate ? moment(renewalForm.legalRenewalConsultDate).format("L") : `_`}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight:'bolder'}}>
                                        Procedure Owner Renewal Consult
                                    </Typography>
                                    <Typography>{renewalForm.procedureOwnerRenewalDate ? moment(renewalForm.procedureOwnerRenewalDate).format("L") : `_`}</Typography>
                                </Grid>
                            </Grid>

                        </CollapsibleCard>

                        <CollapsibleCard
                            title='Procedure Details'
                            divider
                            initiallyOpen
                            subTitle="Provide information the approvers needs to evaluate the exception"
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}
                        >
                            <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                                <Grid item xs={2}  >
                                    <Typography style={{fontWeight:'bolder'}}>Title</Typography>
                                    <Typography>{renewalForm.title}</Typography>
                                </Grid>
                                {/* <Grid item xs={2}  >
                  <Typography>Exception Scope</Typography>
                  <Typography>{renewalForm.exceptionScope}</Typography>
                </Grid> */}
                                
                                <Grid item xs={2}  >
                                    <Typography style={{fontWeight:'bolder'}}> Exception Effective Date </Typography>
                                    <Typography>{renewalForm.exceptionEffectiveDate ? moment(renewalForm.exceptionEffectiveDate).format("L") : `__`}</Typography>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Typography style={{fontWeight:'bolder'}}>Exception Requested By</Typography>
                                    <Typography>{renewalForm.exceptionRequestBy}</Typography>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Typography style={{fontWeight:'bolder'}}>Exception Form Completed By</Typography>
                                    <Typography>{renewalForm.exceptionCompletedBy}</Typography>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Typography style={{fontWeight:'bolder'}}>Exception Classification</Typography>
                                    <Typography>{renewalForm.exceptionClassification}</Typography>
                                </Grid>

                                <Grid item xs={2}  >

                                    <Typography style={{fontWeight:'bolder'}}>Exception Expiration Date </Typography>

                                    <Typography>{renewalForm.exceptionExpirationDate ? moment(renewalForm.exceptionExpirationDate).format("L") : `__`}</Typography>

                                </Grid>
                                <Grid item xs={2}  >

                                    <Typography style={{fontWeight:'bolder'}}>Procedure(s)</Typography>
                                    <Typography>{renewalForm.procedure}</Typography>

                                </Grid>
                                <Grid item xs={2}  >

                                    <Typography style={{fontWeight:'bolder'}}>Geography scope for the exception</Typography>
                                    <Typography>{renewalForm.geographyScopeException}</Typography>

                                </Grid>
                                <Grid item xs={2}  >

                                    <Typography style={{fontWeight:'bolder'}}>Business area scope for the exception</Typography>

                                    <Typography>{renewalForm.businessAreaScope}</Typography>

                                </Grid>
                                <Grid item xs={2}  >
                                    <Typography style={{fontWeight:'bolder'}}>Describe the exception you are requesting</Typography>
                                    <Typography>{renewalForm.exceptionDescription}</Typography>
                                </Grid>
                                
                                <Grid item xs={2}  >
                                    <Typography style={{fontWeight:'bolder'}}>What is the impact on the business if the <br></br> exception is granted? If not granted?</Typography>
                                    <Typography>{renewalForm.exceptionImpactAfterGrant}</Typography>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Typography >What is the rationale for this exception?</Typography>
                                    <Typography>{renewalForm.exceptionRationale}</Typography>
                                </Grid>
                                <Grid item xs={2}  >
                                    <Typography style={{fontWeight:'bolder'}}>Insert text from the procedure for which you <br></br>are requesting an exception</Typography>
                                    <Typography>{renewalForm.requestedTextFromProcedure}</Typography>
                                </Grid>
                            </Grid>
                        </CollapsibleCard>
                        <CollapsibleCard
                            title='Consultation: Ethics and Compliance'
                            divider

                            subTitle="Summarize the advice you received on this potential exception"
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}
                        >
                            <Box>
                                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>ECO/BL</Typography>
                                        <Typography>{renewalForm.ecoBl}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>ECO/BL Consult Date</Typography>
                                        {renewalForm.ecoBlConsultDate ?

                                            <Typography>{moment(renewalForm.ecoBlConsultDate).format("L")}</Typography>
                                            : <Typography>____</Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Per ECO/BL: What do applicable international, regional or local industry association <br></br> code(s) say about the subject?</Typography>
                                        <Typography>{renewalForm.ecoBlAssociationCodeSubjectDescription}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+</Typography>
                                        <Typography>{renewalForm.ecGeographicm4}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+ Consult Date</Typography>
                                        {renewalForm.ecGeographicm4ConsultDate ? <Typography>{moment(renewalForm.ecGeographicm4ConsultDate).format("L")}</Typography> :
                                            <Typography>_____</Typography>}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>E&C Geographic M4+ Comments</Typography>
                                        <Typography>{renewalForm.ecGeographicm4Comments}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations</Typography>
                                        <Typography>{renewalForm.ecAssociateVpOp}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations Consult Date</Typography>
                                        {renewalForm.ecAssociateVpOpConsultDate ? <Typography>{moment(renewalForm.ecAssociateVpOpConsultDate).format("L")}</Typography>
                                            : <Typography>__</Typography>}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>E&C Associate Vice President Operations Comments</Typography>
                                        <Typography>{renewalForm.ecAssociateVpOpComments}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CollapsibleCard>
                        <CollapsibleCard title='Consultation: Legal'
                            divider
                            subTitle="Summarize the advice you received on this potential exception. Legal consult applies to
                        IBU exceptions and is optional for other exceptions."
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>

                            <Box>
                                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Assigned Legal Counsel</Typography>
                                        <Typography>{renewalForm.assignedLegalCounsel}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Legal Consult Date</Typography>
                                        {renewalForm.legalConsultDate ?
                                            <Typography>{moment(renewalForm.legalConsultDate).format('L')}</Typography> :
                                            <Typography>__</Typography>

                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Per Legal: What are the risks associated with the exception?</Typography>
                                        <Typography>{renewalForm.legalRiskWithException}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Per Legal:How is the exception compliant with applicable law(s),local and foreign laws applied <br></br> to the jurisdiction (e.g., U.S, FCPA,trade sanctions,other countries with extraterritorial reach)?</Typography>
                                        <Typography>{renewalForm.exceptionCompliantWithLaws}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CollapsibleCard>
                        <CollapsibleCard title='Consultation: Procedure Owner'
                            divider

                            subTitle="Summarize the advice you received on this potential exception"
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
                            <Box>
                                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Procedure Owner</Typography>
                                        <Typography>{renewalForm.procedureOwner}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Procedure Owner Consult Date</Typography>
                                        {
                                            renewalForm.procedureOwnerConsultDate ?
                                                <Typography>{moment(renewalForm.procedureOwnerConsultDate).format('L')}</Typography>
                                                : <Typography>___</Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Procedure Owner Comments</Typography>
                                        <Typography>{renewalForm.procedureOwnerComments}</Typography>
                                    </Grid>

                                </Grid>
                            </Box>
                        </CollapsibleCard>
                        <CollapsibleCard title='Approval: Global Ethics and Compliance Procedure'
                            divider

                            subTitle="Summarize the advice you received on this potential exception"
                            titleStyle={{ fontSize: '26px' }}
                            subTitleStyle={{ fontSize: '12px', marginLeft: '2.1em', marginTop: '0.2rem' }}>
                            <Box>
                                <Grid container spacing={2} mb={"0.5em"} mt={"0.1rem"} columns={6}>
                                    <Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>Exception Type for Approval</Typography>
                                        <Typography>{renewalForm.exceptionTypeForApproval}</Typography>
                                    </Grid>
                                    {renewalForm.exceptionTypeForApproval === "Global Ethics & Compliance Procedure" ? <><Grid item xs={2}>
                                        <Typography style={{fontWeight:'bolder'}}>M4+ Legal Attorney Supporting ECO/BL (for E&C Procedure)</Typography>
                                        <Typography>{renewalForm.m4LegalAttorneySpEcoBlName}</Typography>
                                    </Grid><Grid item xs={2}>
                                            <Typography style={{fontWeight:'bolder'}}>Regional or Affiliate GM or Business VP at M5/R8+ Level</Typography>
                                            <Typography>{renewalForm.gmRegionalAffiliateVpName}</Typography>
                                        </Grid></> : renewalForm.exceptionTypeForApproval === "Lilly International Affiliate/Hub Local Procedure Requirements" ?
                                        <><Grid item xs={2}>
                                            <Typography style={{fontWeight:'bolder'}}>IBU Hub GM</Typography>
                                            <Typography>{renewalForm.ibuHubGmName}</Typography>
                                        </Grid><Grid item xs={2}>
                                                <Typography style={{fontWeight:'bolder'}}>Associate VP or Above, Ethics and Compliance, IBU</Typography>
                                                <Typography>{renewalForm.associateVpEcoIbuName}</Typography>
                                            </Grid><Grid item xs={2}>
                                                <Typography style={{fontWeight:'bolder'}}>Associate VP or Above, Legal, IBU</Typography>
                                                <Typography>{renewalForm.associateVpLegalIbuName}</Typography>
                                            </Grid></> : renewalForm.exceptionTypeForApproval === "Global Procedure on Following FRAP" ?
                                            <><Grid item xs={2}>
                                                <Typography style={{fontWeight:'bolder'}}>M4+ ECO/BL</Typography>
                                                <Typography>{renewalForm.m4EcoBlName}</Typography>
                                            </Grid><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}}>M4+ Legal Attorney Supporting ECO/BL (for FRAP)</Typography>
                                                    <Typography>{renewalForm.m4LegalAttorneySpEcoBlName}</Typography>
                                                </Grid><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}} >GM or Functional VP at M5/R8 Level</Typography>
                                                    <Typography>{renewalForm.gmFunctionVpr8Name}</Typography>
                                                </Grid><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}}>Chief Accounting Officer </Typography>
                                                    <Typography>{renewalForm.chiefAccOfficerName}</Typography>
                                                </Grid><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}}>Chief Procurement Officer </Typography>
                                                    <Typography>{renewalForm.chiefProcOfficerName}</Typography>
                                                </Grid></>
                                            : renewalForm.exceptionTypeForApproval === "Global Travel and Expense Procedure" ?
                                                <><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}}>Local Business Owner</Typography>
                                                    <Typography>{renewalForm.localBusinessOwnerName}</Typography>
                                                </Grid><Grid item xs={2}>
                                                        <Typography style={{fontWeight:'bolder'}}>Local Chief Financial Officer (CFO)</Typography>
                                                        <Typography>{renewalForm.localChiefFinanOwnerName}</Typography>
                                                    </Grid><Grid item xs={2}>
                                                        <Typography style={{fontWeight:'bolder'}}>Senior Director, Enterprise Travel and Expenser</Typography>
                                                        <Typography>{renewalForm.seniorDirEnterTravExpenName}</Typography>
                                                    </Grid><Grid item xs={2}>
                                                        <Typography style={{fontWeight:'bolder'}}>Senior Vice President and Chief Financial Officer</Typography>
                                                        <Typography>{renewalForm.seniorVpChiefFinanOffcName}</Typography>
                                                    </Grid></>
                                                : <><Grid item xs={2}>
                                                    <Typography style={{fontWeight:'bolder'}}>Local CFO</Typography>
                                                    <Typography>{renewalForm.localCfoName}</Typography>
                                                </Grid><Grid item xs={2}>
                                                        <Typography style={{fontWeight:'bolder'}}>M5/R12 Business Leader</Typography>
                                                        <Typography>{renewalForm.m5F12BusinessLeaderName}</Typography>
                                                    </Grid></>
                                    }
                                </Grid>
                            </Box>
                        </CollapsibleCard>

                    </>
                }
            </Box>
        )

    }

    validationCheck = () => {
        const { renewalForm } = this.state;
        let final: boolean = true;
        let emptyFields = [];
        if (!renewalForm.title) {
          final = false;
          emptyFields.push("Title");
    
        } 
         if (!renewalForm.exceptionSummary) {
          final = false;
          emptyFields.push("Exception Summary");
        }
         if (!renewalForm.rationaleForRenewal) {
          final = false;
          emptyFields.push("Rationale for Renewal");
        } 
         if (!renewalForm.renewalRequestedBy) {
          final = false;
          emptyFields.push("Renewal Requested By");
        } 
         if (!renewalForm.renewalSubmittedBy) {
          final = false;
          emptyFields.push("Renewal Submitted By");
        } 
         if (!renewalForm.expirationDate) {
          final = false;
          emptyFields.push("Expiration Date");
    
        } 
         if (!renewalForm.legalRenewalConsultDate) {
          final = false;
          emptyFields.push("Legal Renewal Consult Date");
    
        } 
         if (!renewalForm.procedureOwnerRenewalDate) {
          final = false;
          emptyFields.push("Procedure Owner Renewal Consult Date");
        } 
        const errMsg = `Please enter the mandatory data for ${emptyFields.join(", ")} `
        this.setState({errMsg, throwErr: !final});
        return final
      }

    validationChecker = (): boolean => {
        const { renewalForm } = this.state;
        let final: boolean = true;
        let emptyFields = [];
        if (!renewalForm.title) {
          final = false;
          emptyFields.push("Title");
         
        } 
        if (!renewalForm.exceptionExpirationDate) {
          final = false;
          emptyFields.push("Exception Expiration Date");
         
        }
        if (!renewalForm.exceptionClassification) {
          final = false;
          emptyFields.push("Exception Classification");
         
        } 
         if (!renewalForm.exceptionRequestBy) {
          final = false;
          emptyFields.push("Exception Requested By");
         
        }
         if (!renewalForm.exceptionCompletedBy) {
          final = false;
          emptyFields.push("Exception Completed By");
          
        }
         if (!renewalForm.procedure) {
          final = false;
          emptyFields.push("Procedure(s)");
         
        } 
         if (!renewalForm.businessAreaScope) {
          final = false;
          emptyFields.push("Business Area Scope for Exception");
          
        } 
         if (!renewalForm.geographyScopeException) {
          final = false;
          emptyFields.push("Geography Scope for Exception");
          
        } 
         if (!renewalForm.exceptionDescription) {
          final = false;
          emptyFields.push("Exception Description");
          
        } 
         if (!renewalForm.exceptionImpactAfterGrant) {
          final = false;
          emptyFields.push("Impact of Exception if Granted");
         
        } 
         if (!renewalForm.exceptionRationale) {
          final = false;
          emptyFields.push("Exception Rationale");
        } 
         if (!renewalForm.exceptionTypeForApproval) {
          final = false;
          emptyFields.push("Exception Type for Approval");
         
        } 
         if (!renewalForm.requestedTextFromProcedure) {
          final = false;
          emptyFields.push("Text from Procedure for which Exception is Requested"); 
         
        } 
        if(renewalForm.exceptionTypeForApproval) {
          // Check fields based on exceptionTypeForApproval
          switch (renewalForm.exceptionTypeForApproval) {
            case "Global Ethics & Compliance Procedure":
              if (this.areObjectValuesEmpty(this.state.gmRegionalAffiliateVpVal)) {
                final = false;
                emptyFields.push("GM or Regional/Affiliate VP at M5/R8+ Level");
                
              } 
               if (this.areObjectValuesEmpty(this.state.m4LegalAttorneySpEcoBlVal)) {
                final = false;
                emptyFields.push("M4+ Legal Attorney Supporting ECO/BL");
               
              }
              break;
            case "Lilly International Affiliate/Hub Local Procedure Requirements":
              if (this.areObjectValuesEmpty(this.state.ibuHubGmVal)) {
                final = false;
                emptyFields.push("IBU Hub GM");
                
              } 
               if (this.areObjectValuesEmpty(this.state.associateVpEcoIbuVal)) {
                final = false;
                emptyFields.push("Associate VP or Above, Ethics and Compliance, IBU");
             
              } 
               if (this.areObjectValuesEmpty(this.state.associateVpLegalIbuVal)) {
                final = false;
                emptyFields.push("Associate VP or Above, Legal, IBU");
               
              }
              break;
            case "Global Procedure on Following FRAP":
              if (this.areObjectValuesEmpty(this.state.m4EcoBlVal)) {
                final = false;
                emptyFields.push("M4+ ECO/BL");
           
              } 
               if (this.areObjectValuesEmpty(this.state.m4LegalAttrEcoBlFrapVal)) {
                final = false;
                emptyFields.push("M4+ Legal Attorney Supporting ECO/BL (for FRAP)");
             
              } 
               if (this.areObjectValuesEmpty(this.state.gmFunctionVpr8Val)) {
                final = false;
                emptyFields.push("GM or Functional VP at M5/R8+ Level");
                
              } 
               if (this.areObjectValuesEmpty(this.state.chiefAccOfficerVal)) {
                final = false;
                emptyFields.push("Chief Accounting Officer");
                
              } 
               if (this.areObjectValuesEmpty(this.state.chiefProcOfficerVal)) {
                final = false;
                emptyFields.push("Chief Procurement Officer");
               
              }
              break;
            case "Global Travel and Expense Procedure":
              if (this.areObjectValuesEmpty(this.state.localBusinessOwnerVal)) {
                final = false;
                emptyFields.push("Local Business Owner");
                
              } 
               if (this.areObjectValuesEmpty(this.state.localChiefFinanOwnerVal)) {
                final = false;
                emptyFields.push("Local Chief Financial Officer (CFO)");
                
              } 
               if (this.areObjectValuesEmpty(this.state.seniorDirEnterTravExpenVal)) {
                final = false;
                emptyFields.push("Senior Director, Enterprise Travel and Expense");
               
              } 
               if (this.areObjectValuesEmpty(this.state.seniorVpChiefFinanOffcVal)) {
                final = false;
                emptyFields.push("Senior Vice President and Chief Financial Officer");
               
              }
              break;
            case "Recognition Expense":
              console.log("switch case")
              if (this.areObjectValuesEmpty(this.state.localCfoVal)) {
               
                final = false;
                emptyFields.push("Local CFO");
           
              } 
               if (this.areObjectValuesEmpty(this.state.m5F12BusinessLeaderVal)) {
                final = false;
                emptyFields.push("M5/R12 Business Leader");
                
              }
              break;
            default:
              // Handle other exceptionTypeForApproval values if needed
              break;
          }
        }
    
        const errMsg = `Please enter the mandatory data for ${emptyFields.join(", ")}.`;
    
        this.setState({ errMsg,throwErr: !final });
    
        return final;
      }
      
      areObjectValuesEmpty(obj: any) {
        return Object.values(obj).every(x => (x === null || x === '' || x === undefined));
      }

    handleSaveAsDraft = async () => {
        const { renewalForm } = this.state;
        // const omitId = omit(renewalForm, ['lperId']);
        const response = exceptionRenewService.saveAsDraft(renewalForm);
        console.log("Renewal Create", response);
        this.setState({ mode: "view",throwSuccess:true,successMsg: "The procedure exception renewal form has been drafted successfully." });
    }

    handleSubmitForApproval = async () => {
        const { renewalForm } = this.state;
        if (this.validationCheck() && this.validationChecker()) {
            const response = await exceptionRenewService.update(renewalForm);
            console.log("Renewal Create", response);
            this.setState({ throwSuccess: true, successMsg: "The procedure exception renewal form has been submitted for approval successfully." })
            setTimeout(() => {
                this.props.history.push('/procedure_exception');
            }, 1200);
        }
    }

    onDropDownChangeException = (selected: SelectModel) => {
        const { renewalForm } = this.state;
        const value = selected.value;
        this.setState({
            renewalForm: { ...renewalForm, exceptionTypeForApproval: value },
        });
    };

    handleEcAssociateVPDateChange = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, ecAssociateVpOpConsultDate: value! },
        });
    };
    handleEcGeographicDateChange = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, ecGeographicm4ConsultDate: value! },
        });
    };
    handleEcBlConsultDateChange = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, ecoBlConsultDate: value! },
        });
    };
    handleExceptionEffDateChange = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, exceptionEffectiveDate: value! },
        });
    };
    handleExceptionExpiryDateChange = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, exceptionExpirationDate: value },
        });
    };
    handleLegalConsultDateChangeException = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, legalConsultDate: value! },
        });
    };
    handleProcedureOwnerDateChangeException = (value: Date | null) => {
        this.setState({
            renewalForm: { ...this.state.renewalForm, procedureOwnerConsultDate: value! },
        });
    };
    getExceptionGeographyData = async () => {
        try {
          const data = await lptService.getAllExceptionGeography();
          // const sortedData = [...data].sort((a: any, b: any) => {return a - b})
          const sortedData = [...data].sort((a: any, b: any) => {
            if (a.value < b.value)
              return -1;
            if (a.value > b.value)
              return 1;
            return 0;
          });
          const dropdowndata = sortedData.map((el) => {
            return {
              id: el.id,
              text: el.text,
              value: el.value
            }
          })
          console.log("data of exceptiongeography", dropdowndata);
          this.setState({ exceptiongeography: [...dropdowndata] })
        } catch (error) {
    
        }
      }
      getExceptionBusinessAreaData = async () => {
        try {
          const data = await lptService.getAllExceptionBusinessArea();
          const sortedData = [...data].sort((a: any, b: any) => {
            if (a.value < b.value)
              return -1;
            if (a.value > b.value)
              return 1;
            return 0;
          });
          const dropdowndata = sortedData.map((el) => {
            return {
              id: el.id,
              text: el.text,
              value: el.value
            }
          })
          console.log("data of exceptionBusinessArea", dropdowndata);
          this.setState({ exceptionBusinessArea: [...dropdowndata] })
        } catch (error) {
    
        }
      };

    getProcedureNamesLocal = async (querySearch: any) => {
        try {
          const data = await gptService.getLocalProcedure(querySearch);
          const sortedData = data!.localProcedures.sort((a: any, b: any) => {
            if (a.value < b.value)
              return -1;
            if (a.value > b.value)
              return 1;
            return 0;
          });
          const dropDownData = sortedData.map((el) => {
            return {
              id: el.lpId,
              text: `${el.templateName}(v${el.versionNumber})`,
              value: `${el.templateName}(v${el.versionNumber})`
            }
          });
          console.log("Procedure Names dropdowndata", dropDownData);
          this.setState({ proceduresData: dropDownData });
          return dropDownData;
        } catch (error) {
          console.log("error", error)
        }
      }
      getProcedureNamesGlobal = async (querySearch: any) => {
        try {
          const data = await gptService.getAllWithPagination(querySearch);
          const sortedData = data!.globalProcedures.sort((a: any, b: any) => {
            if (a.value < b.value)
              return -1;
            if (a.value > b.value)
              return 1;
            return 0;
          });
          const dropDownData = sortedData.map((el) => {
            return {
              id: el.gpId,
              text: `${el.templateName}(v${el.versionNumber})`,
              value: `${el.templateName}(v${el.versionNumber})`
            }
          });
          console.log("Procedure Names dropdowndata", dropDownData);
          this.setState({ proceduresData: dropDownData });
          return dropDownData;
        } catch (error) {
          console.log("error", error)
        }
      };
   

    setAssociateVpEcoIbuInputValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ assocVpECOLoading: true })
        } else {
            this.setState({ assocVpECOLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, assocVpECOLoading: false });
            }
        }
    };
    setAssociateVpLegalIbuInputValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ assocVpIBULoading: true })
        } else {
            this.setState({ assocVpIBULoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, assocVpIBULoading: false });
            }
        }
    };
    setChiefAccOfficerInputValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ chiefAccOffLoading: true })
        } else {
            this.setState({ chiefAccOffLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, chiefAccOffLoading: false });
            }
        }
    };
    setChiefProcOfficerInputValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ chiefProcOfcLoading: true })
        } else {
            this.setState({ chiefProcOfcLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, chiefProcOfcLoading: false });
            }
        }
    };
    setGmFunctionVpr8InputValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ gmFuncVPLoading: true })
        } else {
            this.setState({ gmFuncVPLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, gmFuncVPLoading: false });
            }
        }
    };
    setGmRegionalAffiliateVpInputValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ gmRegionalLoading: true })
        } else {
            this.setState({ gmRegionalLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, gmRegionalLoading: false });
            }
        }
    };
    setIbuHubGmInputValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ ibuHubGmLoading: true })
        } else {
            this.setState({ ibuHubGmLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, ibuHubGmLoading: false });
            }
        }
    };
    setLocalBusinessOwnerValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ localBusOwnerLoading: true })
        } else {
            this.setState({ localBusOwnerLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, localBusOwnerLoading: false });
            }
        }
    };
    setLocalCfoValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ localCFOLoading: true })
        } else {
            this.setState({ localCFOLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, localCFOLoading: false });
            }
        }
    };
    setLocalChiefFinanOwnerValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ localChiefFinOfcLoading: true })
        } else {
            this.setState({ localChiefFinOfcLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, localChiefFinOfcLoading: false });
            }
        }
    };
    setM4EcoBlValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ m4EcoBlLoading: true })
        } else {
            this.setState({ m4EcoBlLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, m4EcoBlLoading: false });
            }
        }
    };
    setM4LegalAttorneySpEcoBlValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ m4LegalAttrLoading: true })
        } else {
            this.setState({ m4LegalAttrLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, m4LegalAttrLoading: false });
            }
        }
    };
    setM4LegalAttrEcoBlFrapValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ m4LegalAttrFRAPLoading: true })
        } else {
            this.setState({ m4LegalAttrFRAPLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, m4LegalAttrFRAPLoading: false });
            }
        }
    };
    setM5F12BusinessLeaderValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ m5F12BLLoading: true })
        } else {
            this.setState({ m5F12BLLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, m5F12BLLoading: false });
            }
        }
    };
    setSeniorDirEnterTravExpenValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ SenrEntrLoading: true })
        } else {
            this.setState({ SenrEntrLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, SenrEntrLoading: false });
            }
        }
    };
    setSeniorVpChiefFinanOffcValue = async (inputValue: any) => {
        this.setState({ approverNames: [] });
        console.log("input value", inputValue);

        const isData = this.state.approverNames.find(
            (p: any) => p.text === inputValue
        );

        if (inputValue) {
            this.setState({ SeniorVPCFOLoading: true })
        } else {
            this.setState({ SeniorVPCFOLoading: false })
        }
        if (inputValue.length > 3 && isData === undefined) {
            let response = await lptService.getApprover({
                searchText: inputValue,
            });
            console.log("response", response);

            if (response) {
               const approverValues: SelectModel[] = response.map((el) => {
          const email = el.EmailAddress ? el.EmailAddress.toLowerCase() : null
          return {
            text: `${el.FirstName} ${el.LastName} (${email ? email : el.EmailAddress}) (${el.SystemLogonId})`,
            value: `${el.FirstName} ${el.LastName}`,
            email: `${el.EmailAddress}`,
          };
        });

                this.setState({ approverNames: approverValues, SeniorVPCFOLoading: false });
            }
        }
    };

    onAssociateVpEcoIbuSelect = (event: any) => {
        this.setState({ assocVpECOLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
            this.setState({
                renewalForm: {
                    ...this.state.renewalForm,
                    associateVpEcoIbuName: event.value,
                    associateVpEcoIbuEmail: event.email,
                },
                associateVpEcoIbuVal: event,
            });
            this.setState({ associateVpEcoIbuVal: event });
        } else {
            this.setState({associateVpEcoIbuVal: {text: "",value: "",email: ""}})
        }
    };
    onAssociateVpLegalIbuSelect = (event: any) => {
        this.setState({assocVpIBULoading : false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
            this.setState({
                renewalForm: {
                    ...this.state.renewalForm,
                    associateVpLegalIbuName: event.value,
                    associateVpLegalIbuEmail: event.email,
                },
                associateVpLegalIbuVal: event,
            });
            this.setState({ associateVpLegalIbuVal: event });
        } else {
            this.setState({associateVpLegalIbuVal: {text: "",value: "",email: ""}})
        }
    };
    onChiefAccOfficerSelect = (event: any) => {
        this.setState({ chiefAccOffLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
            this.setState({
                renewalForm: {
                    ...this.state.renewalForm,
                    chiefAccOfficerName: event.value,
                    chiefAccOfficerEmail: event.email,
                },
                chiefAccOfficerVal: event,
            });
            this.setState({ chiefAccOfficerVal: event });
        } else {
            this.setState({chiefAccOfficerVal: {text: "",value: "",email: ""}})
        }
    };
    onChiefProcOfficerSelect = (event: any) => {
        this.setState({ chiefProcOfcLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              chiefProcOfficerName: event.value,
              chiefProcOfficerEmail: event.email,
            },
            chiefProcOfficerVal: event,
          });
          this.setState({ chiefProcOfficerVal: event });
        } else {
          this.setState({chiefProcOfficerVal: {text: "",value: "",email: ""}})
        }
      };
      onGmFunctionVpr8Select = (event: any) => {
        this.setState({ gmFuncVPLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              gmFunctionVpr8Name: event.value,
              gmFunctionVpr8Email: event.email,
            },
            gmFunctionVpr8Val: event,
          });
          this.setState({ gmFunctionVpr8Val: event });
        } else {
          this.setState({gmFunctionVpr8Val: {text: "",value: "",email: ""}})
        }
      };
      onGmRegionalAffiliateVpSelect = (event: any) => {
        this.setState({ gmRegionalAffiliateVpVal: false, gmRegionalLoading:false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              gmRegionalAffiliateVpName: event.value,
              gmRegionalAffiliateVpEmail: event.email,
            },
            gmRegionalAffiliateVpVal: event,
          });
          this.setState({ gmRegionalAffiliateVpVal: event });
        } else {
          this.setState({gmRegionalAffiliateVpVal: {text: "",value : "" ,email: ""}})
        }
      };
      onIbuHubGmSelect = (event: any) => {
        this.setState({ ibuHubGmLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              ibuHubGmName: event.value,
              ibuHubGmEmail: event.email,
            },
            ibuHubGmVal: event,
          });
          this.setState({ ibuHubGmVal: event });
        } else {
          this.setState({ibuHubGmVal: {text: "",value: "",email: ""}})
        }
      };
      onLocalBusinessOwnerSelect = (event: any) => {
        this.setState({ localBusOwnerLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              localBusinessOwnerName: event.value,
              localBusinessOwnerEmail: event.email,
            },
            localBusinessOwnerVal: event,
          });
          this.setState({ localBusinessOwnerVal: event });
        } else {
          this.setState({localBusinessOwnerVal: {text: "",value: "",email: ""}})
        }
      };
      onLocalCfoSelect = (event: any) => {
        this.setState({ localCFOLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              localCfoName: event.value,
              localCfoEmail: event.email,
            },
            localCfoVal: event,
          });
          this.setState({ localCfoVal: event });
        } else {
          this.setState({localCfoVal: {text: "",value: "",email: ""}})
        }
      };
      onLocalChiefFinanOwnerSelect = (event: any) => {
        this.setState({ localChiefFinOfcLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              localChiefFinanOwnerName: event.value,
              localChiefFinanOwnerEmail: event.email,
            },
            localChiefFinanOwnerVal: event,
          });
          this.setState({ localChiefFinanOwnerVal: event });
        } else {
          this.setState({localChiefFinanOwnerVal: {text: "",value: "",email: ""}})
        }
      };
      onM4EcoBlSelect = (event: any) => {
        this.setState({ m4EcoBlLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              m4EcoBlName: event.value,
              m4EcoBlEmail: event.email,
            },
            m4EcoBlVal: event,
          });
          this.setState({ m4EcoBlVal: event });
        } else {
          this.setState({m4EcoBlVal: {text: "",value: "",email: ""}})
        }
      };
      onM4LegalAttorneySpEcoBlSelect = (event: any) => {
        this.setState({ m4LegalAttrLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              m4LegalAttorneySpEcoBlName: event.value,
              m4LegalAttorneySpEcoBlEmail: event.email,
            },
            m4LegalAttorneySpEcoBlVal: event,
          });
          this.setState({ m4LegalAttorneySpEcoBlVal: event });
        } else {
          this.setState({m4LegalAttorneySpEcoBlVal: {text: "",value: "",email: ""}})
        }
      };
      onM4LegalAttrEcoBlFrapSelect = (event: any) => {
        this.setState({ m4LegalAttrFRAPLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              m4LegalAttrEcoBlFrapName: event.value,
              m4LegalAttrEcoBlFrapEmail: event.email,
            },
            m4LegalAttrEcoBlFrapVal: event,
          });
          this.setState({ m4LegalAttrEcoBlFrapVal: event });
        } else {
          this.setState({m4LegalAttrEcoBlFrapVal: {text: "",value: "",email: ""}})
        }
      };
      onM5F12BusinessLeaderSelect = (event: any) => {
        this.setState({ m5F12BLLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              m5F12BusinessLeaderName: event.value,
              m5F12BusinessLeaderEmail: event.email,
            },
            m5F12BusinessLeaderVal: event,
          });
          this.setState({ m5F12BusinessLeaderVal: event });
        } else {
          this.setState({m5F12BusinessLeaderVal: {text: "",value: "",email: ""}})
        }
      };
      onSeniorDirEnterTravExpenSelect = (event: any) => {
        this.setState({ SenrEntrLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              seniorDirEnterTravExpenName: event.value,
              seniorDirEnterTravExpenEmail: event.email,
            },
            seniorDirEnterTravExpenVal: event,
          });
          this.setState({ seniorDirEnterTravExpenVal: event });
        } else {
          this.setState({seniorDirEnterTravExpenVal: {text: "",value: "",email: ""}})
        }
      };
      onSeniorVpChiefFinanOffcSelect = (event: any) => {
        this.setState({ SeniorVPCFOLoading: false })
        console.log("select event", event);
        // console.log("email",event.email);
        if (event) {
          this.setState({
            renewalForm: {
              ...this.state.renewalForm,
              seniorVpChiefFinanOffcName: event.value,
              seniorVpChiefFinanOffcEmail: event.email,
            },
            seniorVpChiefFinanOffcVal: event,
          });
          this.setState({ seniorVpChiefFinanOffcVal: event });
        } else{
          this.setState({seniorVpChiefFinanOffcVal: {text: "",value: "",email: ""}})
        }
      };

    handleTextInputChange = (event: any) => {
        console.log("ecent", [event.target.name], event.target.value)
        this.setState({
            renewalForm: {
                ...this.state.renewalForm, [event.target.name]: event.target.value
            }
        })
    };

    handleexceptionClassificationRadioButtons = (event: any) => {
        this.setState({
            renewalForm: {
                ...this.state.renewalForm, exceptionClassification: event.target.value
            }
        });
    }
    handleEcAssociateVpOpRadioButtons = (event: any) => {
        this.setState({
            renewalForm: {
                ...this.state.renewalForm, ecAssociateVpOp: event.target.value
            }
        });
    }
    onSelectProcedure = (selected: any) => {
        this.setState({ procedureNames: [...selected] })
    }
    onSelectBusinessScope = (selected: any) => {
        this.setState({ businessScope: [...selected] })
    }
    onSelectGeographyScope = (selected: any) => {
        this.setState({ geographyScope: [...selected] })
    }

    async componentDidMount(): Promise<void> {
        const { renewalForm } = this.state;
        const querySearch = {
            page: 1,
            limit: 2000,
            searchtext: "",
            order: "",
            orderBy: "",
            status: "Submitted",
        }
        console.log('props id',this.props)
        const searchParams = new URLSearchParams(this.props.location.search);
        console.log('idd',Number(this.props.match.params.id))
        this.setState({
            
            lperId: Number(this.props.match.params.id),
            mode: searchParams.get("mode") as ScreenMode,
        }, async() => {
         const renewalData = await this.getRenewalData();
        
        });
        if (this.state.mode === "edit") {
            const querySearchGlobal = {
                page: 1,
                limit: 2000,
                searchtext: "",
                order: "",
                orderBy: "",
                status: "Submitted",
              }
              const querySearchLocal = {
                page: 1,
                limit: 2000,
                searchtext: "",
                order: "",
                orderBy: "",
                status: "Batch Generated",
              }
              let data
              if(renewalForm.formType === "GlobalForm") {
                   data = await this.getProcedureNamesGlobal(querySearchGlobal);
    
              } else {
                data = await this.getProcedureNamesGlobal(querySearchLocal);
              }
            
            this.setState({ proceduresData: data! })
        }
       
    }
}

export default withRouter(ExceptionRenewalViewPage)