import PMPAPIService from "./PMPAPIService";
import {
  GeographyModel,
  GlobalProcedureTemplate,
  GlobalProcedureTemplateSection,
  GlobalProcedureTemplateFile
} from "../Models";
import { GlobalProcedureVersionModel } from "../Models/GlobalProcedureVersionModel";
import { EcoViewCountTab, LocalProcedure, ProcedureException ,GlobalProcedure} from "../Models/GlobalProcedureTemplateModel";

interface IGPTInterfaceDTO {
  globalProcedureTemplate: GlobalProcedureTemplate;
  globalProcedureTemplateSections: GlobalProcedureTemplateSection[];
  globalProcedureTemplateFiles:GlobalProcedureTemplateFile[];
}

class GPTService extends PMPAPIService {
  async getAll(): Promise<GlobalProcedureTemplate[]> {

    const response = await this.GetAsync<GlobalProcedureTemplate[]>(
      `globalproceduretemplate/getall`
    );

    let responseGPTs: GlobalProcedureTemplate[] = [];

    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }

  async getHistoryByGPId(gpId:number):Promise<any> {
    const response = await this.GetAsync<any>(
      `/globalproceduretemplate/getparentgptbyid/${gpId}`
    );

    let responseGPTs: any = [];

    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }


  async createPTGeoGraphyMapping(payload:any ,localProcedurePayload:any): Promise<any> {
    const response = await this.PostAsync<any>(
      `globalproceduretemplate/createglobaltemplategeographmapping`,
     {payload,localProcedurePayload},
    );
    let responseGPTs: any
    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }
  async createGlobalExceptionMapping(payload:any) {
    const response = await this.PostAsync<any>(
      `/localproceduretemplate/setglobalprocedureexception`,
      payload,
    );
    return response;
  }

  async getGptExceptionMapping(): Promise<any> {
    const response = await this.GetAsync<any>(
      "localproceduretemplate/getglobalprocedureexception",
    );
    let responseGPTs: any
    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }

  async getGeoGraphy(gpId: any): Promise<any> {
    const response = await this.GetAsync<any>(
      `globalproceduretemplate/getallgeography/${gpId}`
    );
    let responseGPTs: any
    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }

  async getAllGeographies(): Promise<GeographyModel[]> {
    const response = await this.GetAsync<GeographyModel[]>(
      `globalproceduretemplate/getallgeographies`
    );

    let responseGeographies: GeographyModel[] = [];
    
    if (response.isSuccess) {
      responseGeographies = response.data;
    }
    return responseGeographies;
  }

  
  async getAllWithPagination(querySearch:any): Promise<GlobalProcedure | null> {

    const response = await this.PostAsync<GlobalProcedure>(
      `globalproceduretemplate/getallwithpagination`, querySearch
    );

    let responseGPTs: GlobalProcedure | null = null

    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }

  async getLocalProcedure(querySearch:any): Promise<LocalProcedure | null> {
    const response = await this.PostAsync<LocalProcedure>(
      `localproceduretemplate/getallwithpagination`,
      querySearch
    );

    let responseGPTs: LocalProcedure | null =null;

    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }

  async getProcedureException(querySearch:any): Promise<ProcedureException | null> {
    const response = await this.PostAsync<ProcedureException>(
      `globalproceduretemplate/getallwithpagination`,
      querySearch
    );

    let responseGPTs: ProcedureException | null =null;

    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }
  
  
  async getEcoTabCount(): Promise<EcoViewCountTab[]> {
    const response = await this.GetAsync<EcoViewCountTab[]>(
      `globalproceduretemplate/getallstatuswithcount`
    );

    let responseGPTs: EcoViewCountTab[] = [];

    if (response.isSuccess) {
      responseGPTs = response.data;
    }
    return responseGPTs;
  }

  async create(gpt: IGPTInterfaceDTO): Promise<IGPTInterfaceDTO | any> {
    const response = await this.PostAsync<IGPTInterfaceDTO>(
      `globalproceduretemplate/create`,
      gpt
    );

    let responseGPT: IGPTInterfaceDTO | any = null;

    if (response.isSuccess) {
      responseGPT = response.data;
    }

    return responseGPT;
  }

  async saveAsDraft(gpt: IGPTInterfaceDTO): Promise<IGPTInterfaceDTO | any> {
    const response = await this.PostAsync<IGPTInterfaceDTO>(
      `globalproceduretemplate/saveasdraft`,
      gpt
    );

    let responseGPT: IGPTInterfaceDTO | any = null;

    if (response.isSuccess) {
      responseGPT = response.data;
    }

    return responseGPT;
  }

  async getByGPId(gpId: number): Promise<IGPTInterfaceDTO | null> {
    const response = await this.GetAsync<IGPTInterfaceDTO>(
      `globalproceduretemplate/getbygpid/${gpId}`
    );

    let responseGPT: IGPTInterfaceDTO | null = null;

    if (response.isSuccess) {
      responseGPT = response.data;
    }

    return responseGPT;
  }

  async update(gpt: IGPTInterfaceDTO): Promise<IGPTInterfaceDTO | null> {
    const response = await this.PostAsync<IGPTInterfaceDTO>(
      `globalproceduretemplate/update`,
      gpt
    );

    let responseGPT: IGPTInterfaceDTO | null = null;

    if (response.isSuccess) {
      responseGPT = response.data;
    }

    return responseGPT;
  }

  async updateFilesPath(filePathDTO: {
    headerImageFile: string,
    footerImageFile: string,
    gpTemplateFile: string,
    gpId: number
  }): Promise<{ success: boolean } | null> {
    const response = await this.PostAsync<{ success: boolean }>(`globalproceduretemplate/updatefilespath`, filePathDTO);

    let responseGPT: { success: boolean } | null = null;

    if (response.isSuccess) {
      responseGPT = response.data;
    }

    return responseGPT;
  }


  async getVersionsByGPId(gpId: number): Promise<GlobalProcedureVersionModel | null> {
    const response = await this.GetAsync<GlobalProcedureVersionModel>(
      `globalproceduretemplate/getversionsbygpid/${gpId}`
    );

    let responseGPT: GlobalProcedureVersionModel | null = null;

    if (response.isSuccess) {
      responseGPT = response.data;
      const globalProcedureHistories = responseGPT.globalProcedureHistory.globalProcedureHistories.filter(pHistory => {
        if (pHistory.gpId === responseGPT?.globalProcedure.globalProcedure.gpId) {
          return false;
        }

        return true;
      });
      responseGPT.globalProcedureHistory.globalProcedureHistories = globalProcedureHistories;
    }

    return responseGPT;
  }
}

const gptService = new GPTService();
export default gptService;
