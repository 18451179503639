import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid } from "@mui/material";
import { $getSelection, $isRangeSelection, createEditor, EditorState, LexicalEditor } from "lexical";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
} from '@lexical/selection';
import WhiteButton from "../../Components/Button/WhiteButton";
import PageHeader from "../../Components/Text/PageHeader";
import {
  DocumentConfigModel,
  FileUploadModel,
  GlobalProcedureTemplate,
} from "../../Models";
import { GlobalProcedureTemplateSection } from "../../Models/GlobalProcedureTemplateSection";
import {
  DocumentService,
  DocxService,
  GPTService,
  LPTService,
  ProcedureFileService,
  RTEditorService,
} from "../../Services";
import FormComponent from "./FormComponent";
import SelectModel from "../../Components/Select/SelectModel";
import Utils from "../../Common/Utils";
import { FileType } from "../../Common/Enums";
import MyCustomSnackbar from "../../Components/SnackBar/MyCustomSnackbar";
import { DocumentType } from "../../Common/Enums";
import RedButton from "../../Components/Button/RedButton";
import IconButton from "../../Components/Button/IconButton";
import BackLinkButton from "../../Components/Button/BackLinkButton";
import BreadCrumbModel from "../../Components/BreadCrumb/BreadCrumbModel";
import Constants from "../../Common/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";


const editorStateEmptyJSONString =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';
const editorStateContentJSONString =
  '{ "root": { "children": [{ "children": [{ "detail": 0, "format": 0, "mode": "normal", "style": "", "text": "abdc", "type": "text", "version": 1 }], "direction": "ltr", "format": "", "indent": 0, "type": "paragraph", "version": 1 }], "direction": "ltr", "format": "", "indent": 0, "type": "root", "version": 1 } }';
const editor = createEditor();
const useEmpty = true;
let editorStateJSONString = editorStateEmptyJSONString;
if (!useEmpty) {
  editorStateJSONString = editorStateContentJSONString;
}

interface Props extends RouteComponentProps<any, any, any> { }

type State = {
  sections: GlobalProcedureTemplateSection[];
  addNewTemplate: GlobalProcedureTemplate;
  isLoading: boolean;
  editorState: EditorState;
  draftSuccess: boolean;
  bodyEditorState: EditorState;
  basicPrinciplesEditorChange: EditorState;
  imagePreview: string | null;
  templateBlob: Blob;
  openSuceessMessage: boolean;
  gpId?: number;
  pdfTemplateUrl: string;
  errMsg: string;
  throwErr: boolean;
  generateSelectedValue: string;
  headerImageValidation: boolean;
  footerImageValidation: boolean;
  createTemplateValidation: boolean;
  templateNameValidation: boolean;
  versionNumberValidation: boolean;
  effectiveDateValidation: boolean;
  procedureTypeValidation: boolean;
  ownerValidation: boolean;
  ownerNameValidation: boolean;
  approverValidation: boolean;
  approverNameValidation: boolean;
  footerValidation: boolean;
  basicPrincValidation: boolean;
  headerValidation: boolean;
  bodyTextValidation: boolean;
  policySubCommApprValidation: boolean;
  saveAsDraftButtonsHandler: boolean;
  breadCrumbs: BreadCrumbModel[];
  load: boolean;
  approverNames: any[];
  ownerNames: any[];
  approverSelectedItems: {};
  ownerSelectedItems: {};
  ApproverNameLoading: boolean;
  confirmDeleteSection: boolean;
  removeSectionId: number;
};

class TemplateGenerate extends Component<Props, State> {
  fileUploadInput: HTMLInputElement | null = null;

  constructor(props: Props) {
    super(props);

    const editorState = editor.parseEditorState(editorStateJSONString);
    this.state = {
      confirmDeleteSection: false,
      removeSectionId: 0,
      openSuceessMessage: false,
      sections: [],
      imagePreview: null,
      draftSuccess: false,
      approverNames: [],
      approverSelectedItems: { text: "", value: "", email: "" },
      ownerNames: [],
      ownerSelectedItems: { text: "", value: "", email: "" },
      ApproverNameLoading: false,
      throwErr: false,
      errMsg: "",
      addNewTemplate: {
        templateName: "",
        basicPrinciples: "",
        bodyText: "",
        approver: "",
        approverName: "",
        approverEmail: "",
        effectiveDate: null,
        footer: "",
        footerImageFile: "",
        header: "",
        headerImageFile: "",
        owner: "",
        ownerName: "",
        ownerEmail: "",
        procedureType: "",
        versionNumber: "",
        _basicPrinciples: Utils.getEditorState(),
        _bodyText: Utils.getEditorState(),
        _headerImageFile: {} as FileUploadModel,
        _footerImageFile: {} as FileUploadModel,
        _gpTemplateFile: {} as FileUploadModel,
        _header: Utils.getEditorState(),
        policySubCommAppr: null,
      },
      isLoading: true,
      editorState: editorState,
      bodyEditorState: editorState,
      basicPrinciplesEditorChange: editorState,
      templateBlob: new Blob(),
      pdfTemplateUrl: "",
      generateSelectedValue: "",
      headerImageValidation: false,
      footerImageValidation: false,
      createTemplateValidation: false,
      approverValidation: false,
      approverNameValidation: false,
      basicPrincValidation: false,
      effectiveDateValidation: false,
      policySubCommApprValidation: false,
      footerValidation: false,
      ownerValidation: false,
      ownerNameValidation: false,
      procedureTypeValidation: false,
      templateNameValidation: false,
      versionNumberValidation: false,
      headerValidation: false,
      bodyTextValidation: false,
      saveAsDraftButtonsHandler: false,
      breadCrumbs: [
        {
          label: 'Procedure Template',
          onClick: () => {
            this.props.history.push('/procedure_templates');
          }
        },
        {
          label: 'Generate Procedure Template'
        }
      ],
      load: false
    };
  }

  componentDidMount(): void {
    this.setState({ isLoading: false });
  }

  validationForSaveAsDraft = () => {
    const { addNewTemplate } = this.state;
    let final: boolean = true
    if (!addNewTemplate.templateName) {
      final = false
      this.setState({ templateNameValidation: true });
    }
    return final
  }

  handleSaveAsDraftFormSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const { addNewTemplate, sections } = this.state;
    if (this.validationForSaveAsDraft()) {
      this.setState({ load: true });
     
      addNewTemplate.basicPrinciples = Utils.appendRules(
        addNewTemplate._basicPrinciples.toJSON()
      );
      addNewTemplate.bodyText = Utils.appendRules(
        addNewTemplate._bodyText.toJSON()
      );
      addNewTemplate.header = Utils.appendRules(
        addNewTemplate._header.toJSON()
      );
      let copiedSections: GlobalProcedureTemplateSection[] = [];
      sections.forEach((el) => {
        let sectionBodyText = el._bodyText;
        let sectionHeader = el._header;
        const bodyText = Utils.appendRules(sectionBodyText.toJSON());
        const header = Utils.appendRules(sectionHeader?.toJSON())
        copiedSections.push({ ...el, bodyText: bodyText, header: header });
      });
      this.setState({ sections: copiedSections }, () => {
        console.log("sections", sections)
      });

      const gptresult = await GPTService.saveAsDraft({
        globalProcedureTemplate: addNewTemplate,
        globalProcedureTemplateSections: copiedSections,
        globalProcedureTemplateFiles:[],
      });
      console.log("gpt", gptresult);
      if(gptresult?.statusCode === 400){
        console.log("gptresult", gptresult);
        let body  = JSON.parse(gptresult?.body);
        this.setState({errMsg: body.message, throwErr: true});
        this.setState({ load: false });
        return;
      }
      const gpId = gptresult!.globalProcedureTemplate!.gpId;
      console.log("addNewTemplate = ",addNewTemplate);  
      if (gpId && (addNewTemplate._headerImageFile.file || addNewTemplate._footerImageFile.file)) {
        addNewTemplate._headerImageFile.gpId = gpId;
        addNewTemplate._footerImageFile.gpId = gpId;

        let s3FileKeyForHeader = "";
        let s3FileKeyForFooter = "";
        console.log("addNewTemplate = ",addNewTemplate);
        if(addNewTemplate._headerImageFile?.file)
        {
           s3FileKeyForHeader = `globalproceduretemplate/${gpId}/${DocumentType.GPT_HEADER}/${addNewTemplate.versionNumber}/${addNewTemplate._headerImageFile.name}`;
        }
        if(addNewTemplate._footerImageFile?.file)
        {
           s3FileKeyForFooter = `globalproceduretemplate/${gpId}/${DocumentType.GPT_FOOTER}/${addNewTemplate.versionNumber}/${addNewTemplate._footerImageFile.name}`;
        }
      
      
         addNewTemplate._headerImageFile.s3filekey = s3FileKeyForHeader;
         addNewTemplate._footerImageFile.s3filekey = s3FileKeyForFooter;
        if(addNewTemplate._headerImageFile?.file)
        {
          console.log("calling headerImageFile Uplaod" ,addNewTemplate._headerImageFile?.file);
          await ProcedureFileService.uploadHeaderLogo(addNewTemplate._headerImageFile)
        }
        if(addNewTemplate._footerImageFile?.file)
        {
          console.log("calling _footerImageFile Uplaod",addNewTemplate._footerImageFile);
          await ProcedureFileService.uploadHeaderLogo(addNewTemplate._footerImageFile)
        }
        
        // await ProcedureFileService.uploadFooterLogo(addNewTemplate._footerImageFile)
        const blobData = await this.handleGenerate();
        this.setState({ templateBlob: blobData });
        const fileName = addNewTemplate.templateName.replace(/\s/g, "_")
        const file = new File([blobData], `${fileName}(v${addNewTemplate.versionNumber}).docx`, {
          type: blobData.type,
        });
        console.log("file of document", file)
        const documents3FileKey = `globalproceduretemplate/${gpId}/${DocumentType.GPT_DOCUMENT}/${addNewTemplate.versionNumber}/${file.name}`;
        addNewTemplate._gpTemplateFile.s3filekey = documents3FileKey;
        addNewTemplate._gpTemplateFile.file = file;
        addNewTemplate._gpTemplateFile.name = addNewTemplate.templateName;
        addNewTemplate._gpTemplateFile.fileversion = addNewTemplate.versionNumber;
        addNewTemplate._gpTemplateFile.type = file.type as FileType;
        addNewTemplate._gpTemplateFile.ext = "docx";
        addNewTemplate._gpTemplateFile.gpId = gpId;
        addNewTemplate._gpTemplateFile.sizeInKB = Math.round(file.size / 1024);
        addNewTemplate._gpTemplateFile.sizeInMB = Math.round(
          file.size / 1024 / 1024
        );
        const fileupload = await ProcedureFileService.uploadDocument(
          addNewTemplate._gpTemplateFile
        );
        console.log("fileupload", fileupload);
        await GPTService.updateFilesPath({ headerImageFile: s3FileKeyForHeader, footerImageFile: s3FileKeyForFooter, gpTemplateFile: documents3FileKey, gpId })
      };
      this.setState({ saveAsDraftButtonsHandler: true }, async () => {
        const [bodyText, basicPrinciples, header] = await Promise.all([
          Utils.getHTML(this.state.addNewTemplate.bodyText),
          Utils.getHTML(this.state.addNewTemplate.basicPrinciples),
          Utils.getHTML(this.state.addNewTemplate.header),
        ]);
        const bodyEl = document.getElementById("bodyText");
        const headerEl = document.getElementById("header");
        const basicPrinciplesEl = document.getElementById("basicPrinciples");
        if (bodyEl) {
          bodyEl.innerHTML = bodyText;
        }

        if (headerEl) {
          headerEl.innerHTML = header;
        }

        if (basicPrinciplesEl) {
          basicPrinciplesEl.innerHTML = basicPrinciples;
        }
        copiedSections.forEach((el, ind) => {
          Utils.getHTML(el.bodyText).then((res) => {
            const secEl = document.getElementById(`section-${ind}`);

            if (secEl) {
              secEl.innerHTML = res;
            }
          });

          Utils.getHTML(el.header).then((res) => {
            const headerSecEl = document.getElementById(`sectionHead-${ind}`);
            if (headerSecEl) {
              headerSecEl.innerHTML = res;
            }
          })

        });
      });
      this.setState({ draftSuccess: true });
      setTimeout(() => {
        this.props.history.push(`/view_template/${gpId}?mode=view`, { from: `/view_template/${gpId}?mode=edit` });
        // this.props.history.go(0);
      }, 2000)

    }
  };

  validationChecker = (): boolean => {
    const { addNewTemplate } = this.state;
    let final: boolean = true
    let emptyFields:string[] = [];
    if (!addNewTemplate.templateName) {
      final = false
      emptyFields.push("Template Name")

    }
    if (Object.keys(addNewTemplate._headerImageFile).length === 0) {
      final = false
      emptyFields.push("Header Image")

    }
    if (Object.keys(addNewTemplate._footerImageFile).length === 0) {
      final = false
      emptyFields.push("Footer Image")

    }
    if (!addNewTemplate.procedureType) {
      final = false
      emptyFields.push("Procedure Type")

    }

    if (!addNewTemplate.versionNumber) {
      final = false
      emptyFields.push("Version Number")

    }

    if (!addNewTemplate.effectiveDate) {
      final = false
      emptyFields.push("Effective Date")

    }
    if (!addNewTemplate.policySubCommAppr) {
      final = false
      emptyFields.push("Policy Sub Committee Approval")

    }
    if (!addNewTemplate.owner && addNewTemplate.procedureType === "Local Procedure") {
      final = false
      emptyFields.push("Owner")

    }
    if (!addNewTemplate.approver && addNewTemplate.procedureType === "Local Procedure") {
      final = false
      emptyFields.push("Approver")

    }

    if (!addNewTemplate.footer) {
      final = false
      emptyFields.push("Footer")

    }
    if (Utils.isEditorStateEmpty(addNewTemplate._basicPrinciples) === true) {
      final = false
      emptyFields.push("Basic Principles")

    }

    if (Utils.isEditorStateEmpty(addNewTemplate._header) === true) {
      final = false
      emptyFields.push("Header")
    }
    if (Utils.isEditorStateEmpty(addNewTemplate._bodyText) === true) {
      final = false
      emptyFields.push("Body Text")
    }
    const errMsg = `Please enter mandatory data for ${emptyFields.join(", ")}.`
    this.setState({ errMsg, throwErr: !final });
    return final

  }

  handleCreateForm = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const { addNewTemplate,
      sections, } = this.state;
    console.log("addNewTemplate", addNewTemplate)
    if (this.validationChecker() && this.validationsForSections()) {
      this.setState({ load: true });
      // addNewTemplate.versionNumber = `${addNewTemplate.versionNumber}.0`;
      addNewTemplate.basicPrinciples = Utils.appendRules(
        addNewTemplate._basicPrinciples.toJSON()
      );
      addNewTemplate.bodyText = Utils.appendRules(
        addNewTemplate._bodyText.toJSON()
      );
      addNewTemplate.header = Utils.appendRules(
        addNewTemplate._header.toJSON()
      );
      let copiedSections: GlobalProcedureTemplateSection[] = [];
      sections.forEach((el) => {
        let sectionBodyText = el._bodyText;
        let sectionHeader = el._header;
        const bodyText = Utils.appendRules(sectionBodyText.toJSON());
        const header = Utils.appendRules(sectionHeader?.toJSON())
        copiedSections.push({ ...el, bodyText: bodyText, header: header });
      });
      this.setState({ sections: copiedSections });
      if (addNewTemplate.gpUniqueId === null) {
        this.setState({ addNewTemplate: { ...addNewTemplate, gpUniqueId: addNewTemplate.gpId! } });
      }
      const gptresult = await GPTService.create({
        globalProcedureTemplate: addNewTemplate,
        globalProcedureTemplateSections: copiedSections,
        globalProcedureTemplateFiles: [],
      });
        if(gptresult?.statusCode === 400){
        console.log("gptresult", gptresult);
        let body  = JSON.parse(gptresult?.body);
        this.setState({errMsg: body.message, throwErr: true});
        this.setState({ load: false });
        return;
      }
      const gpId = gptresult!.globalProcedureTemplate?.gpId;

      this.setState({ gpId: gpId, addNewTemplate: { ...addNewTemplate } });
      if (gpId) {
        addNewTemplate._headerImageFile.gpId = gpId;
        addNewTemplate._footerImageFile.gpId = gpId;
        const s3FileKeyForHeader = `globalproceduretemplate/${gpId}/${DocumentType.GPT_HEADER}/${addNewTemplate.versionNumber}/${addNewTemplate._headerImageFile.name}`;
        const s3FileKeyForFooter = `globalproceduretemplate/${gpId}/${DocumentType.GPT_FOOTER}/${addNewTemplate.versionNumber}/${addNewTemplate._headerImageFile.name}`;
        addNewTemplate._headerImageFile.s3filekey = s3FileKeyForHeader;
        addNewTemplate._footerImageFile.s3filekey = s3FileKeyForFooter;
        await ProcedureFileService.uploadHeaderLogo(addNewTemplate._headerImageFile)
        await ProcedureFileService.uploadFooterLogo(addNewTemplate._footerImageFile)
        const blobData = await this.handleGenerate();
        this.setState({ templateBlob: blobData });
        const fileName = addNewTemplate.templateName.replace(/\s/g, "_")
        const file = new File([blobData], `${fileName}(v${addNewTemplate.versionNumber}).docx`, {
          type: blobData.type,
        });
        console.log("file of document", file)
        const documents3FileKey = `globalproceduretemplate/${gpId}/${DocumentType.GPT_DOCUMENT}/${addNewTemplate.versionNumber}/${file.name}`;
        addNewTemplate._gpTemplateFile.s3filekey = documents3FileKey;
        addNewTemplate._gpTemplateFile.file = file;
        addNewTemplate._gpTemplateFile.name = addNewTemplate.templateName;
        addNewTemplate._gpTemplateFile.fileversion = addNewTemplate.versionNumber;
        addNewTemplate._gpTemplateFile.type = file.type as FileType;
        addNewTemplate._gpTemplateFile.ext = "docx";
        addNewTemplate._gpTemplateFile.gpId = gpId;
        addNewTemplate._gpTemplateFile.sizeInKB = Math.round(file.size / 1024);
        addNewTemplate._gpTemplateFile.sizeInMB = Math.round(
          file.size / 1024 / 1024
        );
        const fileupload = await ProcedureFileService.uploadDocument(
          addNewTemplate._gpTemplateFile
        );
        console.log("fileupload", fileupload);
        await GPTService.updateFilesPath({ headerImageFile: s3FileKeyForHeader, footerImageFile: s3FileKeyForFooter, gpTemplateFile: documents3FileKey, gpId })
      }
      

      this.setState({ draftSuccess: false }, async () => {
        const [bodyText, basicPrinciples, header] = await Promise.all([
          Utils.getHTML(this.state.addNewTemplate.bodyText),
          Utils.getHTML(this.state.addNewTemplate.basicPrinciples),
          Utils.getHTML(this.state.addNewTemplate.header),
        ]);
        const bodyEl = document.getElementById("bodyText");
        const headerEl = document.getElementById("header");
        const basicPrinciplesEl = document.getElementById("basicPrinciples");
        if (bodyEl) {
          bodyEl.innerHTML = bodyText;
        }
        if (headerEl) {
          headerEl.innerHTML = header;
        }
        if (basicPrinciplesEl) {
          basicPrinciplesEl.innerHTML = basicPrinciples;
        }
        copiedSections.forEach((el, ind) => {
          Utils.getHTML(el.bodyText).then((res) => {
            const secEl = document.getElementById(`section-${ind}`);

            if (secEl) {
              secEl.innerHTML = res;
            }
          });
          Utils.getHTML(el.header).then((res) => {
            const headerSecEl = document.getElementById(`sectionHead-${ind}`);
            if (headerSecEl) {
              headerSecEl.innerHTML = res;
            }
          })
        });
      });
      this.setState({ openSuceessMessage: true }, async () => {
        setTimeout(() => {
          this.props.history.push(`/view_template/${gpId}?mode=view`, { from: `/view_template/${gpId}?mode=edit` });
          // this.props.history.go(0);
          this.setState({ openSuceessMessage: true })
        }, 2000)
      });

    }
  }



  handleBodyTextEditorChange = (_bodyText: EditorState) => {
    this.setState({
      addNewTemplate: { ...this.state.addNewTemplate, _bodyText: _bodyText },
    });
  };

  handleBasicPrinciplesChange = (_basicPrinciples: EditorState) => {
    this.setState({
      addNewTemplate: {
        ...this.state.addNewTemplate,
        _basicPrinciples: _basicPrinciples,
      },
    });
  };

  handleHeaderEditorChange = (_header: EditorState, index?: number, editor?: LexicalEditor) => {
    editor?.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const fontFamilyStyle = 'font-family';
        const fontSizeStyle = 'font-size';
        const ffSelectionStyle = $getSelectionStyleValueForProperty(selection, fontFamilyStyle);

        if (!ffSelectionStyle) {
          $patchStyleText(selection, {
            [fontFamilyStyle]: Constants.DocFontFamilyBold,
            [fontSizeStyle]: '10',
          });
        }
      }

      this.setState({
        addNewTemplate: {
          ...this.state.addNewTemplate,
          _header: _header
        }
      });
    });
  };

  handleTemplateFormChange = (template: GlobalProcedureTemplate) => {
      this.setState({
        addNewTemplate: template,
      });
    
    };
  handleVersionNumberChange = (template: GlobalProcedureTemplate) => {
      this.setState({
        addNewTemplate: {...this.state.addNewTemplate, versionNumber: template.versionNumber}
      });
    
    };

    onDropDownChange = (selected: SelectModel) => {
      const { addNewTemplate } = this.state;
      const value = selected.value;
      this.setState({
        addNewTemplate: { ...addNewTemplate, procedureType: value },
      });
    };

    handleEffectiveDateChange = (value: Date | null) => {
      this.setState({
        addNewTemplate: { ...this.state.addNewTemplate, effectiveDate: value },
      });
    };
    handlePolicyCommApprDateChange = (value: Date | null) => {
      this.setState({
        addNewTemplate: { ...this.state.addNewTemplate, policySubCommAppr: value },
      });
    };

    handleSectionBodyTextChange = (_bodyText: EditorState, index: number) => {
      const { sections } = this.state;
      const updatedSections = sections.map((section, i) => {
        if (i === index) {
          return { ...section, _bodyText: _bodyText };
        }
        return section;
      });
      this.setState({ sections: updatedSections });
    };

    validationsForSections = () => {
      const { sections } = this.state;
      let final = true;
      let emptyFields = [];
    
      for (let ind = 0; ind < sections.length; ind++) {
        const el = sections[ind];
        if (Utils.isEditorStateEmpty(el._bodyText) === true) {
          final = false;
          emptyFields.push(`Section ${ind + 1} Body Text`);
        }
        if (Utils.isEditorStateEmpty(el._header!) === true) {
          final = false;
          emptyFields.push(`Section ${ind + 1} Header`);
        }
      }
    
      if (!final) {
        const errMsg = `Please enter mandatory data for ${emptyFields.join(", ")} or remove sections.`;
        this.setState({ errMsg, throwErr: true });
      }
    
      return final;
    };
    

    addFormFields = () => {
      const { sections } = this.state;
      let maxSectionId = 0;

      if (sections.length > 0) {
        const sectionIds = sections.map(p => p.sectionId as number);
        maxSectionId = Math.max(...sectionIds);
      }

      const sectionsData: GlobalProcedureTemplateSection = {
        _bodyText: Utils.getEditorState(),
        _header: Utils.getEditorState(),
        sectionId: maxSectionId + 1,
      };
      this.setState((prevState) => ({
        sections: [...prevState.sections, sectionsData],
      }));
    };

    removeFormFields = (sectionId: number) => {
      this.setState({ removeSectionId: sectionId, confirmDeleteSection: true, });
      // this.setState({
      //   sections: this.state.sections.filter((_el) => _el.sectionId !== sectionId),
      // });
    };

    handleHeaderImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {

        const file = event.target.files[0];

        let { addNewTemplate } = this.state;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        const fileModel = {} as FileUploadModel;
        fileReader.onload = async () => {
          const { name, size, type } = file;
          fileModel.name = name;
          fileModel.sizeInKB = Math.round(size / 1024);
          fileModel.sizeInMB = Math.round(size / 1024 / 1024);
          fileModel.type = type as FileType;
          fileModel.ext = type.replace(/(.*)\//g, "");
          fileModel.file = file;
          fileModel.fileversion = addNewTemplate.versionNumber;
          this.setState({
            addNewTemplate: { ...addNewTemplate, _headerImageFile: fileModel },
          });
        };
        fileReader.onloadend = () => {
          this.setState({ imagePreview: fileReader.result as string });
        };
      };
    };

    handleFooterImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        let { addNewTemplate } = this.state;
        const fileReader = new FileReader();
        const fileModel = {} as FileUploadModel;
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          const { name, size, type } = file;
          fileModel.name = name;
          fileModel.sizeInKB = Math.round(size / 1024);
          fileModel.sizeInMB = Math.round(size / 1024 / 1024);
          fileModel.type = type as FileType;
          fileModel.ext = type.replace(/(.*)\//g, "");
          fileModel.file = file;
          fileModel.fileversion = addNewTemplate.versionNumber;
          this.setState({
            addNewTemplate: { ...addNewTemplate, _footerImageFile: fileModel },
          });
        };
      }
    };

    onGenerateButtonActions = async (selected: string) => {
      if (selected === "word") {
        this.setState({ generateSelectedValue: "word" });
        this.props.history.push({
          pathname: "/document_view",
          state: {
            addNewTemplate: this.state.addNewTemplate,
            generateSelectedValue: selected,
          },
        });
      } else {
        this.setState({ generateSelectedValue: "pdf" });
        this.props.history.push({
          pathname: "/document_view",
          state: {
            addNewTemplate: this.state.addNewTemplate,
            generateSelectedValue: selected,
          },
        });
      }
    };

    action = (
      <>
        <Button
          size="small"
          style={{ textDecoration: "underline", color: "#0075A2" }}
          onClick={() => {
            this.props.history.push('/procedure_templates');
            // this.props.history.go(0);
          }}
        >
          VIEW
        </Button>
        {/* <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline" }}
        onClick={() => { }}
      >
        UNDO
      </Button> */}
        <IconButton
          IconType="Cancel"
          onClick={() => {
            this.setState({ openSuceessMessage: false, draftSuccess: false });
          }}
        />
      </>
    );

    setApproverInputValue = async (inputValue: any) => {
      this.setState({ approverNames: [] });
      console.log("input value", inputValue);

      const isData = this.state.approverNames.find(
        (p: any) => p.text === inputValue
      );

      if (inputValue) {
        this.setState({ ApproverNameLoading: true })
      } else {
        this.setState({ ApproverNameLoading: false })
      }

      if (inputValue.length > 3 && isData === undefined) {
        let response = await LPTService.getApprover({
          searchText: inputValue,
        });
        console.log("response", response);

        if (response) {
          const approverValues: SelectModel[] = response.map((el) => {
            return {
              text: `${el.FirstName} ${el.LastName} (${el.EmailAddress}) (${el.SystemLogonId})`,
              value: `${el.FirstName} ${el.LastName}`,
              email: `${el.EmailAddress}`,
            };
          });

          this.setState({ approverNames: approverValues, ApproverNameLoading: false });
        }
      }
    };

    onApproverNameSelect = (event: any) => {
      this.setState({ ApproverNameLoading: false })
      console.log("select event", event);
      // console.log("email",event.email);
      if (event) {
        this.setState({
          addNewTemplate: {
            ...this.state.addNewTemplate,
            approverName: event.value,
            approverEmail: event.email,
          },
          approverSelectedItems: event,
        });
        this.setState({ approverSelectedItems: event });
      }
    };

    onOwnerNameSelect = (event: any) => {
      console.log("select event", event);
      // console.log("email",event.email);
      if (event) {
        this.setState({
          addNewTemplate: {
            ...this.state.addNewTemplate,
            ownerName: event.value,
            ownerEmail: event.email,
          },
          ownerSelectedItems: event,
        });
        this.setState({ ownerSelectedItems: event });
      }
    };

    setOwnerInputValue = async (inputValue: any) => {
      this.setState({ ownerNames: [] });
      console.log("input value", inputValue);

      const isData = this.state.ownerNames.find(
        (p: any) => p.text === inputValue
      );

      if (inputValue.length > 3 && isData === undefined) {
        let response = await LPTService.getApprover({
          searchText: inputValue,
        });
        console.log("response", response);

        if (response) {
          const ownerValues: SelectModel[] = response.map((el) => {
            return {
              text: `${el.FirstName} ${el.LastName} (${el.EmailAddress}) (${el.SystemLogonId})`,
              value: `${el.FirstName} ${el.LastName}`,
              email: `${el.EmailAddress}`,
            };
          });

          this.setState({ ownerNames: ownerValues });
        }
      }
    };

    FieldsValidationaction = (
      <>

      </>
    );

    handleDocToPdfConversion = async () => {
      const data = await ProcedureFileService.docx2pdf(
        this.state.addNewTemplate._gpTemplateFile.s3filekey,
        FileType.DOCX
      );
      return data;
    };

    handleSectionHeaderChange = (_header: EditorState,
      index: number, editor?: LexicalEditor) => {
      editor?.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const fontFamilyStyle = 'font-family';
          const fontSizeStyle = 'font-size';
          const ffSelectionStyle = $getSelectionStyleValueForProperty(selection, fontFamilyStyle);

          if (!ffSelectionStyle) {
            $patchStyleText(selection, {
              [fontFamilyStyle]: Constants.DocFontFamilyBold,
              [fontSizeStyle]: '10',
            });
          }
        }

        const { sections } = this.state;
        const updatedSections = sections.map((section, i) => {
          if (i === index) {
            return { ...section, _header: _header };
          }
          return section;
        });

        this.setState({ sections: updatedSections });
      });

    }
    render() {
      const { breadCrumbs, load } = this.state
      if (load && this.state.openSuceessMessage) {
        return (
          <>
            <MyCustomSnackbar
              message="Procedure Template has been created successfully"
              severity="success"
              actions={<></>}
              open={this.state.openSuceessMessage}
              onClose={() => {
                this.setState({ openSuceessMessage: false });
              }}
            />
            <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>

          </>
        )
      }
      if (load) {
        return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
      }
      // if(!this.state.openSuceessMessage && !load)
      // {
        console.log('section Added Here',this.state.sections)
      return (
        <>
          <Box>
            <>

              {/* <Snackbar
        open={this.state.openSuceessMessage}
        autoHideDuration={6000}
        onClose={() => {
          this.setState({ openSuceessMessage: false });
        }}
        message="Procedure Template has been created successfully."
        action={this.action}
      /> */}
              {/* <MyCustomSnackbar
              message="Procedure Template has been created successfully"
              severity="success"
              actions={this.action}
              open={this.state.openSuceessMessage}
              onClose={() => {
                this.setState({ openSuceessMessage: false });
              }}
            /> */}
              <MyCustomSnackbar
                message="Procedure Template has been drafted successfully."
                severity="success"
                actions={<></>}
                open={this.state.draftSuccess}
                onClose={() => {
                  this.setState({ draftSuccess: false });
                }}
              />
              <MyCustomSnackbar
                message={this.state.errMsg}
                severity="warning"
                actions={this.FieldsValidationaction}
                open={this.state.throwErr}
                onClose={() => {
                  this.setState({ throwErr: false });
                }}
              />

              <Dialog open={this.state.confirmDeleteSection}>
                <Grid container>
                  <Grid item>
                    <DialogTitle color={"red"}>
                      Remove Section
                    </DialogTitle>
                  </Grid>
                  <Grid item>
                    <FontAwesomeIcon
                      icon={faXmark}
                      size="lg"
                      onClick={() => {
                        this.setState({ confirmDeleteSection: false });
                      }}
                      style={{ marginTop: 22, marginLeft: 290, marginRight: 16 }}
                    />
                  </Grid>
                </Grid>
                <DialogContent dividers>
                  <DialogContentText marginTop={2} color={"black"}>
                    Are you sure you want to delete this section?
                    <br></br>

                  </DialogContentText>
                  <DialogContent>
                    <Grid container mt={3} ml={-3}>
                      <Grid item mr={2}>
                        <WhiteButton
                          label="No"
                          onClick={() => {
                            this.setState({ confirmDeleteSection: false });
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <RedButton
                          label="Yes"
                          onClick={() => {
                            this.setState({
                              sections: this.state.sections.filter((_el) => _el.sectionId !== this.state.removeSectionId),
                              confirmDeleteSection: false
                            },
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                </DialogContent>
              </Dialog>
              <div>
                {/* <Grid marginBottom={"1em"} container direction="row">
                <BreadCrumb breadCrumbs={breadCrumbs} />
              </Grid> */}
                <Grid
                  container
                  direction={"row"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1em",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item>
                        <BackLinkButton onClick={() => {
                          this.props.history.push("/procedure_templates")
                          // this.props.history.go(0);
                        }
                        } />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                          maxWidth: "42em",
                        }}
                      >
                        <PageHeader label="Generate Procedure Template" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <WhiteButton
                          label="Open Reference Procedure"
                          onClick={() => {
                            window.open('/proceduretool', '_blank');
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <WhiteButton
                          label="Cancel"
                          onClick={() => {
                            this.props.history.push("/procedure_templates");
                            // this.props.history.go(0);
                          }}

                        />
                      </Grid>
                      <Grid item>
                        <WhiteButton
                          onClick={this.handleSaveAsDraftFormSubmit}
                          label="Save as Draft"
                        />
                      </Grid>
                      <Grid item>
                        <RedButton label="Create" onClick={this.handleCreateForm} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <FormComponent
                  onHeaderCancel={this.handlePreviewCancelClickHeader}
                  onFooterCancel={this.handlePreviewCancelClickFooter}
                  onPolicyCommiteeAppr={this.handlePolicyCommApprDateChange}
                  template={this.state.addNewTemplate}
                  sections={this.state.sections}
                  editorState={this.state.editorState}
                  onTemplateChange={this.handleTemplateFormChange}
                  onVersionNumberChange={this.handleVersionNumberChange}
                  onBodyTextEditorChange={this.handleBodyTextEditorChange}
                  onBasicPrinciplesEditorChange={
                    this.handleBasicPrinciplesChange
                  }
                  onHeaderEditorChange={this.handleHeaderEditorChange}
                  onSectionHeaderChange={this.handleSectionHeaderChange}
                  onSectionBodyTextChange={this.handleSectionBodyTextChange}
                  onEffectiveDateChange={this.handleEffectiveDateChange}
                  onProcedureTypeChange={this.onDropDownChange}
                  onFooterImageUpload={this.handleFooterImage}
                  onHeaderImageUpload={this.handleHeaderImage}
                  onBodyTextAdd={this.addFormFields}
                  onBodyTextRemove={this.removeFormFields}
                  createTemplateValidation={this.state.createTemplateValidation}
                  setApproverInputValue={this.setApproverInputValue}
                  onApproverNameSelect={this.onApproverNameSelect}
                  approverNames={this.state.approverNames}
                  approverSelectedItems={this.state.approverSelectedItems}
                  setOwnerInputValue={this.setOwnerInputValue}
                  onOwnerNameSelect={this.onOwnerNameSelect}
                  ownerNames={this.state.ownerNames}
                  ownerSelectedItems={this.state.ownerSelectedItems}
                  ApproverNameLoading={this.state.ApproverNameLoading}
                />

              </div>
            </>

          </Box>
        </>
      )
      // ;}

    }

    handlePreviewCancelClickHeader = () => {
      this.setState({ addNewTemplate: { ...this.state.addNewTemplate, _headerImageFile: {} as FileUploadModel } })
    }
    handlePreviewCancelClickFooter = () => {
      this.setState({ addNewTemplate: { ...this.state.addNewTemplate, _footerImageFile: {} as FileUploadModel } })
    }

    handleGenerate = async () => {
      const { addNewTemplate, sections } = this.state;
      let type = 'global';
      const configs: DocumentConfigModel[] = [];

      const gptHeaderConfigs = await DocumentService.getGPTHeaderConfigs(
        addNewTemplate
      );
      configs.push(...gptHeaderConfigs);

      const basicPrincipleTextConfigs =
        DocumentService.getGPTBasicPrincipleTextConfigs();
      configs.push(...basicPrincipleTextConfigs);

      const basicPricipleConfigs = RTEditorService.getDocConfigs(
        addNewTemplate._basicPrinciples,
        "basicPrinciple"
      );
      configs.push(...basicPricipleConfigs);


      const headerConfigs = RTEditorService.getDocConfigs(
        addNewTemplate._header,
        "header"
      );
      configs.push(...headerConfigs);
      configs.push({
        type: 'TEXT',
        value: '',
        textTypes: [],
        sectionType: 'CHILDREN',
      });
      const bodyTextConfigs = RTEditorService.getDocConfigs(
        addNewTemplate._bodyText,
        "bodyText"
      );
      configs.push(...bodyTextConfigs);
      configs.push({
        type: 'TEXT',
        value: '',
        textTypes: [],
        sectionType: 'CHILDREN',
      });

      sections.forEach((section, i) => {
        const headerSectionConfigs = RTEditorService.getDocConfigs(
          section._header!,
          `section-header-${section.sectionId}`
        );
        configs.push(...headerSectionConfigs);
        configs.push({
          type: 'TEXT',
          value: '',
          textTypes: [],
          sectionType: 'CHILDREN',
        });
        const bodyTextSectionConfigs = RTEditorService.getDocConfigs(
          section._bodyText,
          `section-body-${section.sectionId}`
        );
        configs.push(...bodyTextSectionConfigs);
        configs.push({
          type: 'TEXT',
          value: '',
          textTypes: [],
          sectionType: 'CHILDREN',
        });
      });

      const ownerApproverTableConfig = DocumentService.getOwnerApproverTableConfig(addNewTemplate, type);
      configs.push(...ownerApproverTableConfig);

      const footerConfigs = await DocumentService.getGPTFooterConfigs(
        addNewTemplate
      );
      configs.push(...footerConfigs);

      console.log("configs", configs);
      const blob = await DocxService.createDocument(configs);
      return blob;
    };
  }

export default TemplateGenerate;