import axios from "axios";
import { Component, Context } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Button, CircularProgress, Grid, InputLabel } from "@mui/material";
import SearchFilter from "../../../Components/Search/SearchFilter";
import IconButton, { IconType } from "../../../Components/Button/IconButton";
import moment from "moment";
import DataLoaderExceptionView from "../../../Components/Table/DataLoaderExceptionView";
import lptService from "../../../Services/LPTService";
import NoRecords from "../../../Components/NoRecords";
import MyCustomSnackbar from "../../../Components/SnackBar/MyCustomSnackbar";
import SelectModel from "../../../Components/Select/SelectModel";
import SingleSelect from "../../../Components/Select/SingleSelect";
import Utils from "../../../Common/Utils";
import { RoleContext } from "../../../Contexts";
import ExceptionRenewalService from "../../../Services/ExceptionRenewal";
import { AuthorizationService } from "../../../Services";
import { AnyARecord } from "dns";
import exceptionRenewService from "../../../Services/ExceptionRenewal";
import _ from "lodash";

interface Props extends RouteComponentProps<any, any, any> { }

type SortType = {
  key: string;
  direction: string;
}

interface State {
  templateData: any;
  page: number;
  order: string;
  orderBy: string;
  // status:string;
  rows: number;
  limit: number;
  gptId?: number;
  search: string;
  searchedData: any
  isloading: boolean;
  searchDebounce: string;
  sortConfig: SortType | null;
  rowTotalCount: number | null;
  tab: number;
  actions: string;
  errMsg: string;
  throwErr: boolean;
  status: string;
  renewalErr:boolean;
  renewMsg:string;
  alreadyRenewed:any;
  tbleData: any;
}

class ExceptionTemplate extends Component<Props, State> {
  private searchTimeout: number | null = null;
  static contextType?: Context<any> = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: 2,
      sortConfig: null,
      templateData: [],
      tbleData: [],
      page: 1,
      rows: 5,
      limit: 5,
      search: "",
      // status: "Exception",
      searchDebounce: "",
      order: "",
      orderBy: "",
      searchedData: [],
      isloading: false,
      rowTotalCount: 0,
      actions: "",
      errMsg: "",
      throwErr: false,
      status: "",
      renewalErr:false,
      renewMsg:"",
      alreadyRenewed:[]
    };
  }
  componentDidMount(): void {
    const querySearch = {
      searchtext: this.state.searchDebounce?.trim(),
      order: this.state.order,
      orderBy: this.state.orderBy,
      status: this.state.status,

    }
    this.getLocalProcedure(querySearch);

  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      (
        prevState.searchDebounce !== this.state.searchDebounce
        || prevState.sortConfig !== this.state.sortConfig || prevState.status !== this.state.status)
    ) {
      const querySearch = {
        searchtext: this.state.searchDebounce?.trim(),
        order: this.state.sortConfig?.key,
        orderBy: this.state.sortConfig?.direction,
        status: this.state.status,


      }
      this.getLocalProcedure(querySearch);

    }
    // if (prevState.search !== this.state.search) { this.searchfield() }
  }

  searchfield = () => {
    const { templateData, search } = this.state
    let searcher = templateData.filter((el: any) =>
      el.templateName.startsWith(search.toString().toUpperCase()))

    if (search.length > 0) {
      console.log(templateData, 'executing');
      this.setState({ searchedData: searcher })
    } else {
      console.log(searcher, 'else'); this.setState({ searchedData: templateData })
    }
  };


  handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ search: event.target.value }, () => {
      console.log("search", this.state.search);
      if (this.state.search.length === 0) {
        this.setState({ searchDebounce: this.state.search });
      }
    })
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.setState({ searchDebounce: this.state.search });
    }
  };

  getLocalProcedure = async (querySearch: any) => {
    this.setState({ isloading: true });
    try {
      const data = await lptService.getAllExceptionDataByPagination(querySearch);
      const exceptionApprovers = ExceptionRenewalService.getExceptionApproverKeys();

      const formatedData = data?.rows?.map((el: any) => {
        return { ...el, createdDate: moment(el.createdDate).format("L") };
      });
      const renewalFormattedDate = data?.renewalFormData?.map((el: any) => {
        return { ...el, createdDate: moment(el.createdDate).format("L") };
      });
      let combinedData = formatedData?.concat(renewalFormattedDate);

      const isGlobalonlyUser = AuthorizationService.isGlobalonlyUser(this.context);
      const isECOonlyUser = AuthorizationService.isECOOnlyUser(this.context);
      
      if (isGlobalonlyUser || isECOonlyUser) {
        combinedData = combinedData.filter((row: any) => {
          let isValid = false;
          exceptionApprovers.forEach((exceptionApprover) => {
            if (row[exceptionApprover] === this.context.userInfo.preferred_username) {
              if (!isValid) {
                isValid = true;
              }
            }
          });

          return isValid;
        });
      }

      console.log("omit all tabs ==", combinedData.length);
      this.setState({ templateData: combinedData });
      this.setState({ isloading: false });
      if (data) {
        this.setState({ rowTotalCount: combinedData?.length });
      }
    } catch (error) {
      this.setState({ isloading: true });
      if (axios.isAxiosError(error)) {
        this.setState({ isloading: false });
        console.log("error message:", error.message);
        return error.message;
      } else {
        this.setState({ isloading: false });
        console.log("unexpected error during fecthing:", error);
        return "An unexpected error occured";
      }

    }
  };

  requestSort = (key: string) => {
    console.log("key== parent", key);
    const { sortConfig } = this.state;
    let direction = 'ASC';
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === 'ASC') {
        direction = 'DESC';
      }
    }
    console.log("sortdata = parent", sortConfig);
    this.setState({ sortConfig: { key, direction } }, () => {
      console.log("sortConfig parent=", sortConfig);
      console.log("sortConfig parent=", key);
    });
    // this.sorter()
  }

  onChangePage = (page: number) => {
    console.log("page parent", page)
    if (page == 0) {
      this.setState({ page: 1 });
    } else {
      this.setState({ page });
    }
  };

  onChangeRow = (row: number) => {
    this.setState({ limit: row });
  };

  clickId = (_fieldClicked: string, record: any) => {
    if (record.status === "Approval Pending" && record.renewal) {
      window.location.assign(`/renewalApproval/${record.lperId}`)
    } else if (record.status === "Approval Pending") {
      window.location.assign(`/exceptionApproval/${record.gperId}`)
    }else if (record.status && record.renewal) {
      window.location.assign(`/exception_renew/${record.lperId}?mode=view`)
    } 
    else {
      window.location.assign(`/raised_exception/${record.gperId}?mode=view`)
    }
  };

  onActionClick = (action: IconType, record: any) => {
    switch (action) {

      case "ArrowRotate":
        if (record.status === "Active" || record.status === "Inactive") {
          this.getRenewalDataByExceptionId(record);
        } else {
          this.setState({ errMsg: `You cannot renew exception with ${record.status.toLowerCase()} status.`, throwErr: true })
        }
        break;
      case "Edit":
        if (record.status === "Drafted" || record.status==='Rejected') {
          window.location.assign(`/raised_exception/${record.gperId}?mode=edit`);
        } else if((record.status === "Drafted" || record.status==='Rejected') && record.renewal )  {
          window.location.assign(`/exception_renew/${record.lperId}?mode=edit`)
        }
        else {
          this.setState({ errMsg: `Exception can be edited in drafted & rejected state only.`, throwErr: true })
        }
        break;
    }
  };

  getRenewalDataByExceptionId = async (record:any) => {
    try {
      const data = await exceptionRenewService.getRenewalDataByExceptionId(record.gperId);
      data.sort((a, b) => b.lperId! - a.lperId!);
      this.setState({alreadyRenewed:data!});
      console.log("data=", data);
      if (_.isEmpty(data) || data[0]!.status === 'Active') {
        this.props.history.push({
          pathname: `/exception_renewal`, state: {
            renewalForm: record
          }
        });
      } else {
        this.setState({ renewalErr: true, renewMsg: `Exception Renewal is already in ${data[0]!.status}.` });
      }
    } catch (error) {
      console.log("error==", error);
    }
  }

  onDropDownChange = (selected: SelectModel) => {

    const value = selected.value;
    if (value === "ALL") {
      this.setState({ status: "" })
    } else {
      this.setState({
        status: value,
      });
    }
  };
  renewAction = (
    <>
      <Button
        color="error"
        size="small"
        style={{ textDecoration: "underline",textTransform:'capitalize' }}
        onClick={() => { 
          this.state.alreadyRenewed[0].status === 'Rejected' ?
          this.props.history.push({pathname: `/exception_renew/${this.state.alreadyRenewed[0].lperId}`,search:'?mode=view'}) :
          this.state.alreadyRenewed[0].status === 'Drafted' ?
          this.props.history.push({pathname: `/exception_renew/${this.state.alreadyRenewed[0].lperId}`,search:'?mode=view'}) :
          this.props.history.push({pathname: `/renewalapproval/${this.state.alreadyRenewed[0].lperId}`,search:'?mode=view'})
        }}
      >
        View Renewal
      </Button>
      <IconButton
        IconType="Cancel"
        onClick={() => {
          this.setState({ renewalErr: false });
        }}
      />
    </>
  );

  render() {
    const { errMsg, throwErr, templateData, page, limit } = this.state;
    if (this.state.isloading) {
      return <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
    }
    const paginatedData = Utils.paginate(templateData, limit, page);
    return (
      <>
        <MyCustomSnackbar
          message={errMsg}
          severity="warning"
          actions={<></>}
          open={throwErr}
          onClose={() => {
            this.setState({ throwErr: false });
          }}
        />
         <MyCustomSnackbar
          message={this.state.renewMsg}
          severity="warning"
          actions={this.renewAction}
          open={this.state.renewalErr}
          onClose={() => {
            this.setState({ renewalErr: false });
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <InputLabel>Search by Procedure</InputLabel>
            <SearchFilter value={this.state.search} onChange={this.handleSearch} onKeyDown={this.onKeyDown}  />
          </Grid>
          <Grid item xs={2}>
            <InputLabel>Search by Status</InputLabel>
            <SingleSelect values={[{ text: "Approval Pending", value: "Approval Pending" }, { text: "Active", value: "Active" },
            { text: "Inactive", value: "Inactive" },
            { text: "Drafted", value: "Drafted" }, { text: "Rejected", value: "Rejected" }]} value={this.state.status}
              onChange={this.onDropDownChange} placeholder="Select Status" AllValue />
          </Grid>
        </Grid>
        {
          this.state.templateData?.length > 0 ?

            <DataLoaderExceptionView
              tab={this.state.tab}
              sortConfig={this.state.sortConfig}
              requestSort={this.requestSort}
              rowsPerPage={this.state.limit}
              page={this.state.page}
              tableData={paginatedData}
              actionArray={["Edit", "ArrowRotate"]}
              onChangePage={this.onChangePage}
              onChangeRow={this.onChangeRow}
              onClickLink={this.clickId}
              rowTotalCount={this.state.rowTotalCount ? this.state.rowTotalCount : 0}
              onClickCheckBox={() => { }}
              onActionClick={this.onActionClick}
            />
            : <Box style={{ marginTop: 32 }}>
              <NoRecords />
            </Box>
        }

      </>
    );
  }

}

export default withRouter(ExceptionTemplate);
